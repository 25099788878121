import React, { useState } from "react";
import SliderModalImg from "@components/Modal/SliderModalImg";

const FeaturedPost = ({ buildData }) => {
  /**
   * USESTATES
   */
  const [openSlideImage, setOpenSlideImage] = useState(false);
  return (
    <>
      {buildData?.media?.length > 0 && (
        <div className="row">
          <div className="heading-featured">
            <h1>Featured Gallery</h1>
          </div>
          <div
            className={`${
              buildData?.media?.length === 1
                ? "col-lg-12 featured-gallery"
                : "col-lg-6 col-md-6 col-sm-6 col-6 featured-gallery"
            }`}
          >
            <img
              src={buildData?.media?.[0]?.path}
              className="rounded"
              // height={buildData?.media?.[0]?.height}
              width={
                buildData?.media?.length === 1
                  ? "100%"
                  : buildData?.media?.[0]?.width
              }
            />
          </div>
          {buildData?.media?.length >= 2 && (
            <div
              className={`${
                buildData?.media?.length >= 2
                  ? "col-lg-6 col-md-6 col-sm-6 col-6 cars"
                  : "col-lg-12 cars"
              }`}
              style={{ paddingLeft: "unset" }}
            >
              <div className="gallery">
                <img
                  src={buildData?.media?.[1]?.path}
                  width={buildData?.media?.[1]?.width}
                  height={buildData?.media?.length === 2 ? "340px" : "165px"}
                  className="rounded"
                />
              </div>
              {buildData?.media?.length > 2 && (
                <div className="gallery-overlay">
                  <img
                    src={buildData?.media?.[2]?.path}
                    width={buildData?.media?.[2]?.width}
                    className="rounded"
                  />
                  {buildData?.media?.length > 3 && (
                    <div
                      class="overlay-last"
                      style={{
                        backgroundColor: buildData?.type
                          ? "#387AFF"
                          : "#E56424",
                      }}
                      onClick={() => setOpenSlideImage(!openSlideImage)}
                    >
                      View all ({buildData?.media?.length - 2})
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          <SliderModalImg
            open={openSlideImage}
            closeModal={() => setOpenSlideImage(false)}
            buildData={buildData}
          />
        </div>
      )}
    </>
  );
};

export default FeaturedPost;
