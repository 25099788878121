import React from "react";
import "@containers/searchcomponent/SearchPage.scss";
import { isValidUrl } from "@utils/validUrl";
import DefaultImg from "@assets/userProfileImgs/default.png";
import BuildSkeleton from "@components/Skeleton/BuildSkeleton";
import StarRating from "@components/StarRating";
import { capitalizeAndAddSpace } from "@utils/capitalizeAddSpace";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { Link } from "react-router-dom";

const GaragePart = ({ statebuild, loading }) => {
  console.log("statebuild", statebuild);
  const res = statebuild?.recommendedBuilds?.value?.docs;
  return (
    <div>
      {" "}
      {loading ? (
        Array.from({ length: 4 }).map((_, index) => (
          <BuildSkeleton key={index} />
        ))
      ) : (
        <>
          <div className="view-all-heading">
            <h2>
              {capitalizeAndAddSpace(statebuild?.recommendedBuilds?.name)}{" "}
            </h2>
            <Link to={`/explore/builds/task`}>
              See More
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.25 11.25L10 7.5L6.25 3.75"
                  stroke="#387AFF"
                  stroke-linecap="round"
                />
              </svg>
            </Link>
          </div>{" "}
          <div className="garage-row">
            {res?.map((item) => {
              return (
                <div className="garage-block" key={item?._id}>
                  <img
                    src={
                      isValidUrl(item?.bannerImage)
                        ? item?.bannerImage
                        : DefaultImg
                    }
                    alt={`${item?.make}-${item?.model}-${item?.year?.value}`}
                  />
                  <div className="info">
                    <StarRating
                      rating={item?.rating}
                      isShow={false}
                      isRedirect={false}
                    />
                    <span className="current">
                      {item?.type == 1 ? "Current" : "Previous"}
                    </span>
                    <div className="model">
                      {item?.year?.value} {item?.make}
                    </div>
                    <div className="name">
                      {sliceStringAtWord(item?.name, 10)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default GaragePart;
