import React from "react";
import "./MultipleUsers.scss";
import { isValidUrl } from "@utils/validUrl";
import { DefaultUserImg } from "@utils/defaultImages";

export const MultipleUsers = ({ property1, crewMembers }) => {
  return (
    <>
      {crewMembers?.map(({ profileImage }, index) => {
        console.log("index", index);
        return (
          <div
            className={`multiple-users ${property1}`}
            style={{
              overflow: "hidden",
              marginLeft: crewMembers?.length > 1 ? "-17px" : "",
            }}
          >
            <img
              className="avatar"
              alt="Avatar"
              src={isValidUrl(profileImage) ? profileImage : DefaultUserImg}
            />
          </div>
        );
      })}
    </>
  );
};


