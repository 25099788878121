import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as YellowLockIcon } from "@assets/YellowLockIcon.svg";
import BannerImg from "@assets/userProfileImgs/default.png";
import { ReactComponent as ArrowRightForward } from "@assets/ArrowRightForward.svg";
import NotFound from "@components/NotFound";
import {
  useGetUserCrewsQuery,
  useGetUserModeratorCrewsQuery,
} from "@redux/services/userProfile/UserProfileService";
import { isValidUrl } from "@utils/validUrl";
import "../UserProfile.scss";
import YourCrewsModal from "@components/Modal/YourCrewsModal";

export default function UserCrews() {
  const { data: { data } = {} } = useGetUserCrewsQuery();
  const { data: { data: crewmoderator } = {} } =
    useGetUserModeratorCrewsQuery();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      {/* ///////crews user is the owner/////////// */}
      <div className="crews-section">
        <div className="container">
          <h2>
            Your Crews
            <span></span>
          </h2>
          {data?.docs?.length > 0 ? (
            <div className="crews-row">
              {data.docs.map((crew) => (
                <Link to={`/crew-post/${crew._id}`} key={crew._id}>
                  <div className="crews-block">
                    <img
                      src={
                        crew?.bannerImage?.includes("https")
                          ? crew.bannerImage
                          : BannerImg
                      }
                      className="main-img"
                    />
                    <div className="info">
                      <div className="name">
                        {crew?.name?.length > 30
                          ? `${crew?.name?.slice(0, 30)}..`
                          : crew?.name}
                        {crew?.privacy === 1 && <YellowLockIcon />}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <NotFound message="Crews" />
          )}
        </div>
      </div>

      {/* ///////crews in which user is moderator/////////// */}

      <h4 className="joinedCrews">Crews You've Joined</h4>
      <div className="crews-joined">
        <div className="container">
          <>
            <h2>
              You're an Admin
              <span
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                {(crewmoderator?.[1]?.value?.length > 3 ||
                  crewmoderator?.[0]?.value?.length > 6) && (
                  <>
                    See More
                    <ArrowRightForward />
                  </>
                )}
              </span>
            </h2>
            {crewmoderator?.[1]?.value?.length > 0 ? (
              <div className="crew-list-content crew-list-user-profile">
                {crewmoderator?.[1]?.value?.slice(0, 3)?.map((join) => (
                  <>
                    <Link
                      to={`/crew-post/${join?.crew?._id}`}
                      style={{ color: "white" }}
                    >
                      <div className="list-block">
                        <img
                          src={
                            isValidUrl(join?.crew?.bannerImage)
                              ? join?.crew?.bannerImage
                              : BannerImg
                          }
                          alt={join?.crew?.name}
                          className="main-img"
                        />
                        <div className="list-info">
                          <h4>
                            {join?.crew?.name?.length > 8
                              ? `${join?.crew?.name?.slice(0, 8)}..`
                              : join?.crew?.name}
                            {join?.crew?.privacy === 1 && <YellowLockIcon />}
                          </h4>
                        </div>
                      </div>
                    </Link>
                  </>
                ))}
              </div>
            ) : (
              <NotFound message="Crews" />
            )}
          </>
        </div>
      </div>

      {/* ///////crews user joined/////////// */}

      <div className="crews-joined">
        <div className="container">
          <>
            <h2>You're Member of</h2>
            {crewmoderator?.[0]?.value?.length > 0 ? (
              <div className="crew-list-content crew-list-user-profile">
                {crewmoderator?.[0]?.value?.slice(0, 6)?.map((join) => (
                  <>
                    <Link
                      to={`/crew-post/${join?.crew?._id}`}
                      style={{ color: "white" }}
                    >
                      <div className="list-block">
                        <img
                          src={
                            isValidUrl(join?.crew?.bannerImage)
                              ? join?.crew?.bannerImage
                              : BannerImg
                          }
                          alt={join?.crew?.name}
                          className="main-img"
                        />
                        <div className="list-info">
                          <h4>
                            {join?.crew?.name?.length > 8
                              ? `${join?.crew?.name?.slice(0, 8)}..`
                              : join?.crew?.name}
                            {join?.crew?.privacy === 1 && <YellowLockIcon />}
                          </h4>
                        </div>
                        {/* <span className="notification">9</span> */}
                      </div>
                    </Link>
                  </>
                ))}
              </div>
            ) : (
              <NotFound message="crew" />
            )}
            {isOpen && (
              <YourCrewsModal
                open={isOpen}
                closeModal={() => setIsOpen(false)}
              />
            )}
          </>
        </div>
      </div>
    </div>
  );
}
