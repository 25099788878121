import React, { useState, useMemo, Suspense } from "react";
import ReactInstaStories, { WithHeader } from "react-insta-stories";
import "./SpotlightViews.scss";
import {
  Main_story,
  CloseStories,
  DefaultImg,
  DefaultUserImg,
  StoriesUp,
  StoriesDown,
} from "@utils/defaultImages";
import { isValidUrl } from "@utils/validUrl";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import StoriesSkeleton from "@components/Skeleton/StoriesSkeleton";
import { dateTimeFromNow } from "@utils/DateFormatter";
import { useLazyListSpotlightQuery } from "@redux/services/Stories/storyServices";
import useMediaQueryHook from "@utils/hooks/useMediaQueryHook";

const CustomFooter = React.lazy(() => import("./CustomFooter"));
const CustomHeader = React.lazy(() => import("./CustomHeader"));

const customCollapsedComponent = ({ story, storyItem }) => {
  return (
    <div className="custom-header">
      <div className="wrap-footer">
        <Suspense fallback={<div>Loading footer...</div>}>
          <CustomFooter storyList={story} />
        </Suspense>
      </div>
    </div>
  );
};

const storyInnerStyles = {
  justifyContent: "center",
};
const progressContainerStyles = {
  position: "absolute",
  bottom: "0",
  width: "94%",
  borderRadius: "22px",
  zIndex: 9999,
};
const storyContainerStyles = {
  maxWidth: "482px",
  maxHeight: "853px",
};

const SpotlightView = () => {
    const { isMobileSmall, isMobile, isTablet, isTabScr } = useMediaQueryHook();

  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [listSpotlight, { isLoading, isFetching }] =
    useLazyListSpotlightQuery(page);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalStories, setTotalStories] = useState(0);
  const [combinedStories, setCombinedStories] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  // const storiesFilter = feedStories?.filter(
  //   (item) => item?.length > 0
  // );
  // let totalStories = feedStories?.docs?.length || 0;



  const location = useLocation();
  const spotlightData = location.state?.spotlight;
  const fetchStories = async () => {
    try {
      const response = await listSpotlight(page).unwrap();
      let stories = [...response?.data?.docs];
      if (page > 1) {

        setCombinedStories((prevStories) => [...prevStories, ...stories]);

      }
      if (spotlightData && page === 1) {
        let previousData = spotlightData?.media;
        previousData = { ...previousData, user: spotlightData?.user, _id: spotlightData?._id };
        stories.unshift(previousData);
        setCombinedStories(stories);

      }

      setTotalStories((prevStories) => prevStories + stories.length);
      setHasNextPage(response?.data?.hasNextPage);
    } catch (error) {
      console.log(error);
    }

  };
  useEffect(() => {

    fetchStories();


  }, [page])
  const handleNextPage = () => {
    if (hasNextPage) {
      setPage(page + 1);
    }
  }
  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (
      scrollTop + clientHeight >= scrollHeight &&
      currentIndex < totalStories - 1
    ) {
      setCurrentIndex(currentIndex + 1);
      if (currentIndex === totalStories - 2) {
        handleNextPage();
      }

    } else if (scrollTop === 0 && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
    useEffect(() => {
      if (location.pathname === "/spotlight-list") {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
      return () => {
        document.body.style.overflow = "auto";
      };
    }, [location.pathname]);
    const dynamicHeight =
      isMobileSmall || isMobile
        ? "83vh"
        : isTablet
        ? "88vh"
        : isTabScr
        ? "85vh"
        : "100vh";
  const storyComponents = useMemo(
    () =>
      combinedStories?.map((storyItem, storyIndex) => (
        <div
          className="col-lg-12 my-3 d-flex justify-content-end for-video"
          key={storyIndex}
        >
          <ReactInstaStories
            stories={[
              {
                header: {
                  heading: storyItem?.user?.username,
                  // subheading: dateTimeFromNow(storyItem?.createdAt),
                  profileImage: isValidUrl(storyItem?.user?.profileImage)
                    ? storyItem?.user?.profileImage
                    : DefaultUserImg,
                },
                // seeMore: ({ close }) => (
                //   <div className="custom-header">
                //     <div className="wrap-footer">
                //       <Suspense fallback={<div>Loading footer...</div>}>
                //         <CustomFooter storyList={storyItem} />
                //       </Suspense>
                //     </div>
                //   </div>
                // ),
                // seeMoreCollapsed: (props) =>
                //   customCollapsedComponent({
                //     ...props,
                //     story: storyItem,
                //     storyItem,
                //   }),
                url: storyItem?.path,
                type: "video", // You can check if it's a video or image here if necessary
                // duration: (Math.round(storyItem?.media?.duration) || 5) * 1000,
                autoplay: true,
                muted: true,
              },
            ]}
            storyInnerContainerStyles={storyInnerStyles}
            progressContainerStyles={progressContainerStyles}
            storyContainerStyles={storyContainerStyles}
            width={"100%"}
            height={dynamicHeight}
            currentIndex={setCurrentIndex}
          />
        </div>
      ))[(combinedStories, currentIndex)]
  );



  // useEffect(() => {
  //   if (location.pathname === "/stories-list") {
  //     const styleElement = document.createElement("style");
  //     styleElement.innerHTML = `
  //     body::-webkit-scrollbar {
  //       display: none;
  //     }
  //   `;
  //     document.head.appendChild(styleElement);

  //     return () => {
  //       document.body.style.overflow = "auto";
  //       document.head.removeChild(styleElement);
  //     };
  //   }
  // }, [location.pathname]);
  return (
    <>
      {isLoading ? (
        <StoriesSkeleton />
      ) : (
        <>
          <div className="container stories-container" onScroll={handleScroll}>
            {isFetching ? (
              <StoriesSkeleton />
            ) : (
              <div className="row">{storyComponents}</div>
            )}
          </div>
          {!(isMobile || isMobileSmall) && (
            <div>
              <CloseStories className="pointer" onClick={() => navigate(-1)} />
              <div className="d-flex flex-column gap-3 position-fixed top-50">
                {currentIndex > 0 && (
                  <StoriesUp
                    onClick={() => setCurrentIndex(currentIndex - 1)}
                    className="pointer"
                  />
                )}
                {(currentIndex < totalStories - 1 || hasNextPage) && (
                  <StoriesDown
                    onClick={() => setCurrentIndex(currentIndex + 1)}
                    className="pointer"
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SpotlightView;
