import React from "react";
import WrapperWarningModal from "./wrapperWarningModal";
import Error from "@assets/membershipDashboard/error.svg";

const MemberShipWarningModal = ({ open, closeModal }) => {
  const handleClose = (str) => {
    if (str === 'sure') {
      closeModal(str)
    }
    else {

      closeModal('');
    }
  };

  return (
    <WrapperWarningModal
      open={open}
      closeModal={closeModal}
      style={{
        top: "50%",
        width: "315px",
        height:"400px",
        transform: "translateY(-50%)",
        borderRadius: "10px",
        left: 0,
        right: 0,
        margin: "0 auto",
        backgroundColor: "#18191A",
        boxShadow: "2px 8px 20px -6px #0A0A0A",
      }}
    >
      <div className="warning-modal-body">
        <div className="warningImage">
          <img src={Error} alt="Error icon" />
        </div>
        <p>Are you sure you want to unlink Speed Society Account from Carvonix?</p>
        <div className="linkToSocietyBtn">
          <button onClick={() => handleClose("cancel")}>Cancel</button>
          <button style={{ backgroundColor: "#E83434" }} onClick={() => handleClose("sure")}>Yes, I am sure</button>
        </div>
      </div>
    </WrapperWarningModal>
  );
};

export default MemberShipWarningModal;
