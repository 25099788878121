import { useParams } from "react-router";
import ReadMore from "components/ReadMore";
import { Link } from "react-router-dom";
import PrivacyComp from "components/Privacy/PrivacyComp";
import { BannerImg } from "utils/defaultImages";
import { useGetCreatorAndModeratorsQuery } from "@redux/services/crewService/allCrewService";
import { isValidUrl } from "@utils/validUrl";

const About = ({ crewData = {}, user = {} }) => {
  const { crewId } = useParams();
  /**
   * API CALLS
   */
  const { data: { data: { docs: moderatorsData } = [] } = {}, isLoading } =
    useGetCreatorAndModeratorsQuery(crewId);
  //

  const checkPrivacy = !!(
    crewData?.userId !== user?._id &&
    crewData?.privacy &&
    !crewData?.isJoin
  );

  return (
    <>
      {checkPrivacy ? (
        <PrivacyComp />
      ) : (
        <div className="tab-pane">
          {" "}
          {/* Updated 'class' to 'className' */}
          <div className="about-crew">
            <>
              {crewData?.description?.length > 0 && (
                <>
                  <h3>About Crew</h3>
                  <ReadMore sliceText={200}>{crewData?.description}</ReadMore>
                </>
              )}

              {/* ModeratorData */}
              {moderatorsData?.length > 0 && (
                <>
                  <div className="mt-3"></div>
                  <h3>Creator</h3>
                  <div className="crew-list">
                    {moderatorsData?.map((mode) => {
                      return (
                        mode?.isOwner && (
                          <>
                            <div className="user-detail" key={mode._id}>
                              <Link
                                to={
                                  user?._id === mode._id
                                    ? `/profile`
                                    : `/profile/${mode._id}`
                                }
                              >
                                <img
                                  src={
                                    isValidUrl(mode?.profileImage)
                                      ? mode?.profileImage
                                      : BannerImg
                                  }
                                  className=""
                                  alt={mode?.username}
                                />
                              </Link>
                              <div>
                                <Link
                                  to={
                                    user?.user?._id === mode._id
                                      ? `/profile`
                                      : `/profile/${mode._id}`
                                  }
                                  style={{ color: "white" }}
                                >
                                  <div className="name">
                                    <h4>{mode?.username}</h4>
                                  </div>
                                </Link>
                                <div className="name-text">
                                  {mode?.firstName} {mode?.lastName}
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      );
                    })}
                  </div>
                  {moderatorsData?.docs?.length > 1 && (
                    <>
                      <div className="mt-3"></div>
                      <h3>Moderators</h3>
                      <div className="crew-list">
                        {moderatorsData?.docs?.map((mode) => {
                          console.log("mode", mode);
                          return (
                            !mode?.isOwner && (
                              <>
                                <div className="user-detail" key={mode._id}>
                                  <Link
                                    to={
                                      user?.user?._id === mode._id
                                        ? `/profile`
                                        : `/profile/${mode._id}`
                                    }
                                  >
                                    <img
                                      src={
                                        mode?.profileImage
                                          ? mode?.profileImage
                                          : BannerImg
                                      }
                                      className=""
                                      alt={mode?.username}
                                    />
                                  </Link>
                                  <div>
                                    <Link
                                      to={
                                        user?.user?._id === mode._id
                                          ? `/profile`
                                          : `/profile/${mode._id}`
                                      }
                                      style={{ color: "white" }}
                                    >
                                      <div className="name">
                                        <h4>{mode?.username}</h4>
                                      </div>
                                    </Link>
                                    <div className="name-text">
                                      {mode?.firstName} {mode?.lastName}
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          );
                        })}
                      </div>
                      <div className="mb-3"></div>
                    </>
                  )}
                </>
              )}

              {crewData?.rules?.length > 0 && (
                <>
                  <h3>Rules</h3>
                  <div className="rules-tag">
                    {crewData?.rules?.map((item, index) => (
                      <span key={index}>{item}</span>
                    ))}
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default About;
