import React, { useEffect, useState } from "react";
import BannerImg from "@assets/car.jpg";
import "./SearchPage.scss";
import SideBarMain from "@components/SideBarMain";
import { MultipleUsers } from "../Crew/components/MultipleUsers";
import { capitalizeAndAddSpace } from "@utils/capitalizeAddSpace";
import DefaultUserImg from "@assets/defaultUser.png";
import ExploreAccounts from "./ExploreAccounts";
import ExploreFilters from "./ExploreFilters";

const SearchResults = () => {
  const [userinfo, setUserInfo] = useState({
    years: [],
  });
  const [activeCategory, setActiveCategory] = useState("Builds");
  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };
  useEffect(() => {
    const toggleIcons = (event) => {
      const icon = event.currentTarget.querySelector("i");
      if (icon.classList.contains("fa-chevron-down")) {
        icon.classList.remove("fa-chevron-down");
        icon.classList.add("fa-chevron-up");
      } else {
        icon.classList.remove("fa-chevron-up");
        icon.classList.add("fa-chevron-down");
      }

      const allIcons = document.querySelectorAll(".acc-btn i");
      allIcons.forEach((otherIcon) => {
        if (otherIcon !== icon) {
          otherIcon.classList.remove("fa-chevron-up");
          otherIcon.classList.add("fa-chevron-down");
        }
      });
    };

    const accordionButtons = document.querySelectorAll(".acc-btn");
    accordionButtons.forEach((button) => {
      button.addEventListener("click", toggleIcons);
    });

    return () => {
      accordionButtons.forEach((button) => {
        button.removeEventListener("click", toggleIcons);
      });
    };
  }, []);
 
  const exploreData = [{}];
  return (
    <div>
      <div
        className="page-container"
        style={{
          gridTemplateColumns:
            activeCategory == "Accounts"
              ? "15% 81% calc(30% - 75px)"
              : "15% 55% calc(30% - 75px)",
        }}
      >
        <SideBarMain />
        <div>
          <div className="full-search">
            <div className="search-form">
              <div className="search-fld">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search for anything"
                />
                <span className="active-tag">
                  {activeCategory}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      d="M8.82869 8.82828L3.17184 3.17142"
                      stroke="white"
                      stroke-linecap="round"
                    />
                    <path
                      d="M8.82816 3.17142L3.17131 8.82828"
                      stroke="white"
                      stroke-linecap="round"
                    />
                  </svg>
                </span>
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <path
                      d="M12.0094 12.5102L16.666 17.1667"
                      stroke="#F5F5F5"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.33398 13.8333C11.0954 13.8333 13.334 11.5947 13.334 8.83325C13.334 6.07183 11.0954 3.83325 8.33398 3.83325C5.57256 3.83325 3.33398 6.07183 3.33398 8.83325C3.33398 11.5947 5.57256 13.8333 8.33398 13.8333Z"
                      stroke="#F5F5F5"
                      stroke-width="1.5"
                    />
                  </svg>
                </button>
              </div>
            </div>
            {/* <div className="search-cate">
              <span className="active">Builds</span>
              <span>Crews</span>
              <span>Accounts</span>
              <span>Businesses</span>
              <span>Tags</span>
              <span>Posts</span>
              <span>HowTo</span>
              <span>Reviews</span>
            </div> */}
            <div className="search-cate">
              {[
                "Builds",
                "Crews",
                "Accounts",
              ].map((category) => (
                <span
                  key={category}
                  className={category === activeCategory ? "active" : ""}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category}
                </span>
              ))}
            </div>
            {activeCategory == "Builds" && (
              <>
                <div className="view-all-heading">
                  <h2>Builds</h2>
                </div>

                <div className="garage-row garage-row-two-clm">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => (
                    <div className="garage-block">
                      <img src={BannerImg} />
                      <div className="info">
                        <div className="stars">
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i className="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star-half-stroke"></i>
                        </div>
                        <span className="current">Current</span>
                        <div className="model">2020 Ford</div>
                        <div className="name">Shelby GT500 </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {activeCategory == "Crews" && (
              <>
                <div className="view-all-heading">
                  <h2>{capitalizeAndAddSpace("Crews")} </h2>
                </div>{" "}
                <div
                  className="crew-garage-row"
                  style={{ gridTemplateColumns: "1fr 1fr" }}
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => (
                    <>
                      <div
                        className="garage-block"
                        style={{ position: "relative" }}
                      >
                        <img
                          src={BannerImg}
                          // alt={`${item?.make}-${item?.model}-${item?.year?.value}`}
                        />
                        <div className="info">
                          <div className="model">Crew Name</div>
                          <div className="name">MeetUp</div>
                        </div>
                        <div
                          className="group-2"
                          style={{
                            position: "absolute",
                            top: "10px",
                            left: "10px",
                            display: "flex",
                            flexDirection: "row-reverse",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div
                            className="text-wrapper-3"
                            style={{ fontSize: "14px", fontWeight: 400 }}
                          >
                            1.2k members
                          </div>
                          <MultipleUsers
                            avatar={DefaultUserImg}
                            avatar1={DefaultUserImg}
                            avatar2={DefaultUserImg}
                            avatar3={DefaultUserImg}
                            avatar4={DefaultUserImg}
                            className="multiple-users-instance"
                            img={DefaultUserImg}
                            property1="users-sm"
                          />
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </>
            )}
            {activeCategory == "Accounts" && <ExploreAccounts />}
          </div>
        </div>
        <ExploreFilters
          activeCategory={activeCategory}
          userinfo={userinfo}
          setUserInfo={setUserInfo}
        />
      </div>
    </div>
  );
};

export default SearchResults;
