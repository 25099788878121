import React, { useCallback, useEffect, useState } from "react";
import BannerWithSearch from "./components/BannerWithSearch";
import "@containers/searchcomponent/SearchPage.scss";
import BannerImg from "@assets/car.png";
import PostPage from "./components/PostPage";
import AccountParts from "./components/AccountParts";
import CrewPart from "./components/CrewPart";
import GaragePart from "./components/GaragePart";

const ExplorePage = () => {
  const [ExploreValue, setExploreValue] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  let getAllPostsForExplore;
  let state = {};
  ExploreValue?.forEach((obj) => {
    state[obj.name] = obj;
  });
  useEffect(() => {
    getAllPostsExplore();
    setLoading(true);
  }, []);
  const getAllPostsExplore = useCallback(async () => {
    try {
      const res = await getAllPostsForExplore();
      setExploreValue(res?.data?.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <div>
        <BannerWithSearch />
        <div>
          {/* Garage Row */}
          <div className="Builds-car">
            <GaragePart
              BannerImg={BannerImg}
              statebuild={state}
              loading={loading}
            />
            {/* Crew list heading */}
            <CrewPart
              BannerImg={BannerImg}
              stateCrew={state}
              setPage={setPage}
              page={page}
              loading={loading}
            />
            {/* Accounts details */}
            <AccountParts
              BannerImg={BannerImg}
              stateAccount={state}
              loading={loading}
            />
            {/* post details */}
            <PostPage statePost={state} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExplorePage;
