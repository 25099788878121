import React from 'react'
import VideoPlayer from "@components/VideoPlayer";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from "react-responsive-carousel";
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation'; 


import { Navigation, Pagination } from 'swiper/modules';
const MediaShow = ({ feedPost }) => {
  return (
    <>
      {feedPost?.media?.length > 1 ? (  
        <div className="borderforAll">
          <Swiper
            pagination={{
              dynamicBullets: true,
            }}
            navigation={true}  
            modules={[Pagination,Navigation]}
            className="mySwiper"
          >
            {feedPost?.media?.map((mediaData, index) => (
              <SwiperSlide key={index}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // width:"100%"
                  }}
                >
                  {mediaData?.type === "1" ? (
                    <>
                      {mediaData?.path?.includes("https") && (
                        <img

                          src={mediaData.path}
                          alt=""
                          className="vid-img-how"
                          style={{
                            // width: `${mediaData.width}px`,
                            // height: `${mediaData.height}px`,
                            aspectRatio: mediaData.aspectRatio,
                          }}
                        />
                      )}
                    </>
                  ) : mediaData?.type === "2" ? (
                    <>
                      {mediaData?.path?.includes("https") && (
                        <VideoPlayer
                          src={mediaData?.path}
                          autoPlay={false}
                          muted={true}
                          isHeight={true}
                          style={{
                            // width: `${mediaData.width}px`,
                            // height: `${mediaData.height}px`,
                            aspectRatio: mediaData.aspectRatio,
                          }}
                        />
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <>
          {feedPost?.media?.map((mediaData, index) => (
            <>
              {mediaData?.type === "1" ? (
                <>
                  {mediaData?.path?.includes("https") && (
                    <div className="borderforAll" key={index}>
                      <img
                        src={mediaData.path}
                        alt=""
                        className="vid-img-how"
                      />
                    </div>
                  )}
                </>
              ) : mediaData?.type === "2" ? (
                <>
                  {mediaData?.path?.includes("https") && (
                    <div className="borderforAll">
                      <VideoPlayer
                        src={mediaData?.path}
                        autoPlay={false}
                        muted={true}
                      />
                    </div>
                  )}
                </>
              ) : null}
            </>
          ))}
        </>
      )}
      {feedPost?.componentStatsId !== undefined && (
        <Link
          to={`${feedPost.parentType === 0 ? `/general-feedPost/${feedPost?._id}` : ""}`}
        >
          <div
            className="feedPost-submission-info-2"
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <span>6 Steps</span>
            {/* <span>{dateTimeFromNow(feedPost?.createdAt)}</span> */}
            {feedPost?.subCategory?.value && (
              <>
                <span>
                  {/* <LocationIcon /> */}
                  {(feedPost?.subCategory?.value && feedPost?.subCategory?.value) || ""}
                </span>
              </>
            )}
          </div>
        </Link>
      )}
    </>
  );
};

export default MediaShow;

