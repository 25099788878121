import React from "react";
import Replies from "../Replies/Replies";
import { DefaultUserImg } from "@utils/defaultImages";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { dateTimeFromNow } from "@utils/DateFormatter";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { isValidUrl } from "@utils/validUrl";
import SpinnerLoader from "../../../Skeleton/SpinnerLoader";
import CommentForm from "../CommentForm/CommentForm";
import { useCommentOnPostMutation } from "@redux/services/commentsService/commentService";

const CommentsSchema = Yup.object().shape({
  commentText: Yup.string().max(800, "Limit Exceeded!"),
});

const ShowAllComments = React.memo(({
  showComments,
  dataComments,
  isTablet,
  Loading,
  postId,
  onNewComment,
  loadMore,
  storePrevComment,
  fetchingNextPageData,
}) => {
  // Formik handle
  const formik = useFormik({
    initialValues: {
      commentText: "",
    },
    validationSchema: CommentsSchema,
  });
  const {
    errors,
    touched,
    values, 
    handleChange,
    handleReset,
    handleBlur,
    isValid,
    resetForm,
  } = formik;
  // API calling
  const [commentOnPost, { isLoading }] = useCommentOnPostMutation();

  // Store comments function
  const storeComments = async (e) => {
    e.preventDefault();
    const body = {
      commentText: values.commentText,
      modelId: postId,
      model: "post",
    };
    try {
      const response = await commentOnPost(body).unwrap();
      if (response?.code === 200) {
        resetForm();
        onNewComment();
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  // Sort comments by createdAt in descending order
  // const sortedComments = [...storePrevComment].sort((a, b) => {
  //   return new Date(b.createdAt) - new Date(a.createdAt);
  // });
  return (
    <>
      {showComments && (
        <CommentForm
          onSubmit={storeComments}
          formik={formik}
          submitText="Comment"
          disableSubmit={!isValid || values?.commentText === ""}
          isDisableBtn={isLoading}
          onChange={handleChange}
          value={values?.commentText}
          onBlur={handleBlur}
          touched={touched}
          message={errors?.commentText}
        />
      )}
      {Loading ? (
        <SpinnerLoader />
      ) : (
        showComments &&
        dataComments?.docs?.map((com) => (
          <div className="add-paginated" key={com?._id}>
            <div className="comment-text" id="comment-section">
              <div className="wrap-comment-header">
                <div className="avtar-in-post">
                  <Link to={`/profile/${com?.userId}`} className="profile-link">
                    <img
                      src={
                        isValidUrl(com?.user?.profileImage)
                          ? com?.user?.profileImage
                          : DefaultUserImg
                      }
                      alt={com?.user?.username}
                      className="main-img"
                    />
                  </Link>
                  <div style={{ marginLeft: "10px" }}>
                    <div className="name">
                      <Link
                        to={`/profile/${com?.userId}`}
                        className="profile-link"
                      >
                        {com?.user?.username.length > 15
                          ? sliceStringAtWord(
                              com?.user?.username,
                              isTablet ? 5 : 15
                            )
                          : com?.user?.username}
                        {com?.user?.username?.length >= 7
                          ? `${sliceStringAtWord(com?.user?.username, 7)}...`
                          : com?.user?.username}
                      </Link>
                    </div>
                  </div>
                  <div className="dot"></div>
                  <div className="d-flex align-items-center">
                    {com?.createdAt && dateTimeFromNow(com?.createdAt)}
                  </div>
                </div>
                {/* <Link role="button">
                  <ThreeDots />
                </Link> */}
              </div>
              <div className="user-text">
                {/* {com?.commentText && ( */}
                <div className="show-more-title">
                  <Replies
                    com={com}
                    isTablet={isTablet}
                    updateCommentCount={onNewComment}
                  />
                </div>
                {/* )} */}
              </div>
            </div>
          </div>
        ))
      )}
      {/* {showComments && dataComments?.page !== dataComments?.totalPages && (
        <div className="show-more-comments" onClick={() => loadMore()}>
          Show more comments
        </div>
      )} */}
    </>
  );
});

export default ShowAllComments;
