import React from "react";
import Unlink from "@assets/membershipDashboard/unlink.svg";
import Arrow from "@assets/membershipDashboard/chevron-right.svg";
import ArrowUp from "@assets/membershipDashboard/arrow-up.svg";
import Doller from "@assets/membershipDashboard/dolar.svg";
import Entries from "@assets/membershipDashboard/Group 41756.svg";
import OnlineStatusDot from "@containers/BuildProfile/components/OnlineStatusDot";
import ButtonLoader from "@components/Loaders/ButtonLoader";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";

const SpeedSocietyTab = ({
  isFetching,
  isUnlinking,
  userInfo,
  speedSoietyConnected,
  handleConnection,
  handleLinkToSociety,
  isLinking,
}) => {
  return (
    <>
      {/* Speed Society Tab Content */}
      <div className="speed-society-statistics">
        <p>Speed Society Statistics</p>
        <button
          className={`connectionButton ${
            speedSoietyConnected === 1 ? "connected" : ""
          }`}
          onClick={handleConnection}
        >
          <img src={Unlink} alt="Unlink" />
          <p>
            {speedSoietyConnected === 0
              ? "Disconnected"
              : isUnlinking
              ? <ButtonLoader />
              : "Connected"}
          </p>
        </button>
      </div>

      <div className="connected-store">
        {isFetching ? (
          <div className="spinner">
            <SpinnerLoader />
          </div>
        ) : (
          <>
            <div className="connected-speed-store">
              <div className="total">
                <div className="entries-image">
                  <img src={Doller} alt="Dollar icon" />
                  <p>Total Entries</p>
                </div>

                <div className="total-entries-data">
                  <p
                    className={`entries-text ${
                      userInfo.speedSociety.entries !== null &&
                      speedSoietyConnected === 1
                        ? "connected"
                        : "disconnected"
                    }`}
                  >
                    {userInfo.speedSociety.entries !== null &&
                    speedSoietyConnected === 1
                      ? `$${userInfo.speedSociety.entries}`
                      : "---"}
                  </p>
                  {userInfo?.speedSociety?.entries !== null &&
                  speedSoietyConnected === 1 ? (
                    <div className="points-last-week">
                      <img src={ArrowUp} alt="Arrow Up icon" />
                      <p>
                        ${userInfo?.speedSociety?.entries} from last week
                      </p>
                    </div>
                  ) : (
                    <p className="no-data-text">No Data</p>
                  )}
                </div>
              </div>
              <div className="total">
                <div className="spent-image">
                  <img src={Entries} alt="Entries icon" />
                  <p>Total Spent</p>
                </div>
                <div className="total-entries-data">
                  <p
                    className={`entries-text ${
                      userInfo.speedSociety.totalSpent &&
                      speedSoietyConnected === 1
                        ? "connected"
                        : "disconnected"
                    }`}
                  >
                    {userInfo.speedSociety.totalSpent &&
                    speedSoietyConnected === 1
                      ? `$${Math.ceil(userInfo?.speedSociety?.totalSpent)}`
                      : "---"}
                  </p>
                  {userInfo?.speedSociety?.totalSpent &&
                  speedSoietyConnected === 1 ? (
                    <div className="points-last-week">
                      <img src={ArrowUp} alt="Arrow Up icon" />
                      <p>
                        ${Math.ceil(userInfo?.speedSociety?.totalSpent)} from
                        last week
                      </p>
                    </div>
                  ) : (
                    <p className="no-data-text">No Data</p>
                  )}
                </div>
              </div>
            </div>
            {speedSoietyConnected === 0 && (
              <div className="linkToSocietyBtn">
                <button
                  style={{ width: "100%" }}
                  onClick={handleLinkToSociety}
                >
                  {isLinking ? <ButtonLoader /> : "Link To Speed Society"}
                </button>
              </div>
            )}
          </>
        )}
      </div>

      <div className="speedSocietyAffiliate">
        <p>Become a Speed Society affiliate</p>
        <img src={Arrow} alt="Arrow icon" />
      </div>
      <div className="AboutSpeedSociety">
        <p>About Speed Society’s affiliate program</p>
        <img src={Arrow} alt="Arrow icon" />
      </div>
      <div className="AboutSpeedSociety">
        <p>Learn More</p>
        <img src={Arrow} alt="Arrow icon" />
      </div>
    </>
  );
};

export default SpeedSocietyTab;
