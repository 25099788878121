import React, { useCallback, useEffect, useState } from "react";
import "./TabSwitch.scss";
import { Link, useParams } from "react-router-dom";
import {
  useLazyGetUserFollowersQuery,
  useLazyGetUserFolowingsQuery,
} from "@redux/services/userProfile/UserProfileService";
import { isValidUrl } from "@utils/validUrl";
import { useSelector } from "react-redux";
import FadingLoader from "@components/Skeleton/CrewSkeleton";
import { toast } from "react-toastify";
import NotFound from "@components/NotFound";
import { useDebounce } from "use-debounce";
import {
  DefaultUserPng,
  SearchIcon,
  ChevronRight,
  SeeData,
} from "@utils/defaultImages";
import { sliceStringAtWord } from "@utils/sliceStringWord";

const FollowingList = () => {
  /**
   * GLOBALLY STATES
   */
  const selfUser = useSelector((state) => state?.auth?.loginUserData);
  const { userId } = useParams();
  /**
   * USESTATES
   */
  const [searchResult, setSearchResult] = useState([]);
  const [inputSearch, setInputSearch] = useState("");
  const [page, setPage] = useState(1);
  const [isApiComplete, setIsApiComplete] = useState(false);

  /**
   * DEBOUNCE
   */
  const [debouncedSearch] = useDebounce(inputSearch, 1000);

  /**
   * SEARCH API
   */
  const [listAllFollowing, { data: { data } = [], isLoading, isFetching }] =
    useLazyGetUserFolowingsQuery();
  // console.log("inputSearch---??", searchResult);
  /**
   * FUNCTIONS CALL
   */

  const showFollowersList = async (searchTerm = "") => {
    const body = {
      page: page,
      userId: userId,
      search: searchTerm,
    };
    try {
      const response = await listAllFollowing(body).unwrap();
      setSearchResult((prevItems) =>
        page === 1
          ? response?.data?.following?.docs
          : [...prevItems, ...response?.data?.following?.docs]
      );
      // setPage((prev) => prev + 1);
      setIsApiComplete(true);
    } catch (error) {
      toast.error(error?.data?.message);
      setIsApiComplete(true);
    }
  };

  const handleChange = (e) => {
    setInputSearch(e.target.value);
  };

  /**
   * USEEFFECT
   */
  useEffect(() => {
    setIsApiComplete(false);

    showFollowersList(debouncedSearch); // Call API with debounced search term
  }, [debouncedSearch, page]);
  return (
    <>
      {
        <div className="crew-list-content">
          {/* Search bar */}
          <div className="full-search">
            <div className="search-form">
              <div className="search-fld">
                <SearchIcon />
                <input
                  value={inputSearch}
                  type="search"
                  className="form-control"
                  placeholder="Search people"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          {isLoading ? (
            <>
              <div className="list-block position-relative">
                <div className="container-fluid p-0">
                  <FadingLoader />
                </div>
              </div>
            </>
          ) : (
            <>
              {isApiComplete && searchResult?.length === 0 ? (
                <NotFound message="Following" />
              ) : (
                searchResult.map((crew) => (
                  <div
                    className="d-flex justify-content-between align-items-center"
                    key={crew?._id}
                  >
                    <div className="list-block-foll">
                      <Link to={`/profile/${crew?._id}`}>
                        <img
                          src={
                            isValidUrl(crew?.profileImage)
                              ? crew?.profileImage
                              : DefaultUserPng
                          }
                          alt={crew?.username}
                          className="main-img"
                        />
                      </Link>
                      <Link
                        to={
                          selfUser?._id === crew?._id
                            ? `/profile`
                            : `/profile/${crew?._id}`
                        }
                        className="profile-link"
                      >
                        <div className="list-info">
                          <h4>{sliceStringAtWord(crew?.username, 15)}</h4>
                          {/* <p style={{ whiteSpace: "nowrap" }}>
                            {crew?.firstName} {crew?.lastName}
                          </p> */}
                        </div>
                      </Link>
                    </div>
                    <ChevronRight />
                  </div>
                ))
              )}
              {isApiComplete && data?.following?.nextPage && (
                <div
                  className="text-center py-3"
                  onClick={() => setPage(data?.following?.nextPage)}
                >
                  <SeeData role="button" />
                </div>
              )}
            </>
          )}
        </div>
      }
    </>
  );
};

export default FollowingList;
