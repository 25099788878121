import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./CheckYourEmail.scss";
import { showNotification } from "@utils/utilityFunctions";
import { debounce } from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import RenderError from "@components/Error/RenderError";
import ConfirmPasswordModal from "@components/Modal/ConfirmPasswordModal";
import {
  useResetEmailMutation,
  useVerifyOtpMutation,
} from "@redux/services/auth/loginService";
import { otpValid } from "@utils/validationFunctions";

const SignInSchema = Yup.object().shape({
  code: Yup.string()
    .matches(/^\d{6}$/, "Must only contain 6 digits")
    .required("Code is required"),
});

const CheckYourEmail = React.memo(({ userData, closeModal, resetModal }) => {
  const [resetEmail] = useResetEmailMutation();
  const [verifyOtp] = useVerifyOtpMutation();
  const [openConfirmPswrd, setOpenConfirmPswrd] = useState(false);
  const [isDisableBtn, setDisableBtn] = useState(false);
  const navigate = useNavigate();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: SignInSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setDisableBtn(true);
      try {
        const validate = otpValid(values.code, userData.userId);
        if (!validate.valid) {
          setErrorMsg(validate.errors);
          setDisableBtn(false);
          return;
        }
        const verifyOtpBody = {
          otp: values.code,
          userId: userData.userId,
        };
        const response = await verifyOtp(verifyOtpBody).unwrap();
        if (response.code === 200) {
          setOpenConfirmPswrd(true);
          setDisableBtn(false);
        }
      } catch (error) {
        setFieldError("code", error.data.message);
        setDisableBtn(false);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleDidNotReceiveCode = useCallback(
    debounce(async (e) => {
      e.preventDefault();
      setMinutes(1);
      setSeconds(30);
      try {
        if (!userData.email) {
          showNotification("Provide an email first", "error");
          return;
        }
        const emailForReset = { email: userData.email };
        await resetEmail(emailForReset);
      } catch (error) {
        showNotification(error?.response?.data?.message || error?.message);
      }
    }, 2000),
    [resetEmail, userData.email]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds((prev) => prev - 1);
      }
      if (seconds === 0 && minutes > 0) {
        setMinutes((prev) => prev - 1);
        setSeconds(59);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds, minutes]);

  useEffect(() => {
    formik.validateForm();
  }, []);

  return (
    <div style={{ minHeight: "700px" }}>
      <div
        className="login-wrapper"
        style={{ minHeight: "auto", padding: "50px 0" }}
      >
        <div className="wrapper-parent">
          <div className="wrapper">
            <p className="reset-sec">Check your email.</p>
            <p>
              We have sent password reset instructions to your email{" "}
              {userData.email}.
            </p>
            {(seconds > 0 || minutes > 0) && (
              <p className="count-down">
                {minutes < 10 ? `0${minutes}` : minutes}:
                {seconds < 10 ? `0${seconds}` : seconds}
              </p>
            )}
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group my-4 mb-3">
                <input
                  type="text"
                  placeholder="Code"
                  className="form-control"
                  name="code"
                  value={formik.values.code}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setErrorMsg("");
                    setDisableBtn(false);
                  }}
                  onBlur={formik.handleBlur}
                />
                {errorMsg && <RenderError message={errorMsg} />}
                {formik.touched.code && (
                  <RenderError message={formik.errors.code} />
                )}
              </div>
              <button
                type="submit"
                disabled={isDisableBtn || !formik.isValid}
                className="login-btn"
              >
                {formik.isSubmitting ? "Sending..." : "Next"}
              </button>
            </form>
            <button
              type="button"
              onClick={handleDidNotReceiveCode}
              disabled={seconds > 0 || minutes > 0}
              className="btn-style my-2"
            >
              Didn’t receive a code?
            </button>
          </div>
        </div>
      </div>
      <ConfirmPasswordModal
        openConfirmPswrd={openConfirmPswrd}
        closeModal={() => setOpenConfirmPswrd(false)}
        userData={userData}
        checkEmailModal={closeModal}
        resetModal={resetModal}
      />
    </div>
  );
});

export default CheckYourEmail;
