import { toast } from "react-toastify";
const replaceAll = (value, str) => {
  console.log('value,str', value,str)
  if (!value) {
    return str;
  }
  return value?.replace(/\s/g, "");
};
const showNotification = (message, type) => {
  const customNotification = { 
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
  };
  if (type === "success") {
    toast.success(message, customNotification);
  } else if (type === "error") {
    return toast.error(message, customNotification);
  } else if (type === "warning") {
    toast.warning(message, customNotification);
  } else if (type === "info") {
    toast.info(message, customNotification);
  }
};
export { replaceAll, showNotification };
