import { Event_image, Event_image_Mob } from "@utils/defaultImages";
import useMediaQueryHook from "@utils/hooks/useMediaQueryHook";
import React from "react";

const MeetUpEvents = () => {
  const { isMobile, isTablet, isMobTab } = useMediaQueryHook();

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 meet-events">
            <h1>Meet ups</h1>
            <p className="pt-3">
              Schedule scenic stops, share routes, and experience the joy of
              driving in a convoy with fellow enthusiasts.{" "}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            {isMobile || isTablet || isMobTab ? (
              <img src={Event_image_Mob} alt="Event_image_Mob" />
            ) : (
              <img src={Event_image} alt="Event_image" />
            )}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12 d-flex flex-column justify-content-center">
            <div className="schedule">
              <h3>Schedule Your Event, Spread the Word, and Get Involved</h3>
              <p>
                From small group meetups to city-wide events, planning meetups
                and connecting with fellow enthusiasts is a few clicks away.
              </p>
            </div>
            <div className="schedule">
              <h3>Find Your Drive, Find Your Community.</h3>
              <p>
                With GPS functionality, public/private event accessibility, and
                much more, finding your community is simple—whether its across
                town, or across the globe.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MeetUpEvents;
