import logo from "@assets/logo-header.svg";
import "./headerFooter.scss";
import "./../SideBarMain.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import SideBarMain from "./../SideBarMain";
import Logout from "@assets/logout.svg";
import UserImg from "@assets/defaultUser.png";
import SettingIcon from "@assets/SettingsIcon.svg";
import Search from "@assets/Search.svg"
import DropDowns from "./DropDowns";
import { isValidUrl } from "@utils/validUrl";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { useMediaQuery } from "react-responsive";
import { clearAuth } from "@redux/slices/authSlice/loginSlice";
import { SetclearRegToken } from "@redux/slices/authSlice/signUpSlice";
import { useLogoutAppMutation } from "@redux/services/auth/loginService";
function Header() {
  const isMobile = useMediaQuery({ maxWidth: 599 });
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.auth?.userAuthInfo);
  const userData = useSelector((state) => state?.auth?.loginUserData);
  const [isSearchExpand, setSearchExpand] = useState(false);
  const [logoutApp] = useLogoutAppMutation();

  const firstName = userData?.firstName || "";
  const lastName = userData?.lastName || "";

  function toggleMenu() {
    setIsSidebarOpen(!isSidebarOpen);
  }
  const handleWindowResize = () => {
    if (window.innerWidth <= 580) {
      setIsSidebarOpen(false);
    }
  };
  const handleExpandSearch = () => {
    console.log("hey")
    setSearchExpand(!isSearchExpand)
    console.log(isSearchExpand)
  };

  useEffect(() => {
    // Add an event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const handleLogout = async (e) => {
    e.preventDefault();
    // user?.tokens?.refresh?.token ||
    const body = {
      refreshToken: user?.refresh?.token,
    };
    if (user?.access?.token) {
      dispatch(clearAuth(""));
      dispatch(SetclearRegToken({}));
      navigate("/login");
      await logoutApp(body).unwrap();


      // dispatch(storeUserId(""));
    }
  };
  const [isAltLeftPressed, setIsAltLeftPressed] = useState(false);
  const [isControlLeftPressed, setIsControlLeftPressed] = useState(false);

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.code === "AltLeft") {
        setIsAltLeftPressed(true);
      } else if (e.code === "ControlLeft") {
        setIsControlLeftPressed(true);
      }
    }

    function handleKeyUp(e) {
      if (e.code === "AltLeft") {
        setIsAltLeftPressed(false);
      } else if (e.code === "ControlLeft") {
        setIsControlLeftPressed(false);
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);
  useEffect(() => {
    // Add the 'no-scroll' class to the body when the sidebar is open
    if (isSidebarOpen) {
      document.body.classList.add("no-scroll");
    } else {
      // Remove the 'no-scroll' class when the sidebar is closed
      document.body.classList.remove("no-scroll");
    }

    // Cleanup: Remove the 'no-scroll' class when the component unmounts
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    if (isAltLeftPressed && isControlLeftPressed) {
      const searchInput = document.getElementById("search-focus");
      if (searchInput) {
        searchInput.focus();
      }
    }
  }, [isAltLeftPressed, isControlLeftPressed]);
  const formattedFirstName =
    firstName.length > 15 ? sliceStringAtWord(firstName, 4) : firstName;
  const formattedLastName =
    lastName.length > 15 ? sliceStringAtWord(lastName, 5) : lastName;
  const fullName = formattedFirstName + " " + formattedLastName;

  return (
    <>
      <header>
        <div
          className={`container${user?.access?.token ? "-fluid" : ""
            } headerContent justify-content-between`}
        >
          <div className="row m-0 p-0 align-items-center">
            <div className="col-lg-3 col-md-4 col-sm-3  col-3 logo">
              <Link to="/main-feed" className="headerLogo">
                <img src={logo} alt="Carvonix Logo" />
              </Link>
            </div>

            <div className="col-lg-9 col-md-8 col-sm-9 col-9 rightbarContent">
              <div className="rightbar" style={{ textAlign: "right" }}>
                {/* <Hamburger /> */}
                {user?.access?.token || user?.token ? (
                  <>
                    {/* <div style={{ position: "relative" }}>
                      <input
                        type="search"
                        placeholder="Search for anything"
                        className="search"
                      />
                      <button className="searchBtn" type="submit">
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </button>
                    </div> */}


                    {!isMobile && (
                      <>
                        <DropDowns />
                        <Link className="downloadBtn" >
                          Download App
                        </Link>
                        <div className="dropdown ms-2">

                          <Link
                            className=""
                            to={`/profile`}
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >

                            {fullName}
                            {/* {user?.user?.length > 15
                          ? `${
                              sliceStringAtWord(filter?.user?.username, 15) +
                              "..."
                            }`
                          : filter?.user?.username} */}
                            {/* {user?.user?.firstName + " " + user?.user?.lastName} */}
                            <img
                              style={{}}
                              src={
                                isValidUrl(userData?.profileImage)
                                  ? userData?.profileImage
                                  : UserImg
                              }
                              className="avtar-img"
                              alt={fullName}
                            />
                          </Link>

                          <ul className="dropdown-menu">
                            <li>
                              <Link className="dropdown-item" to={`/profile`}>
                                <img
                                  style={{
                                    border: "2px solid #898A8D",
                                    borderRadius: "50%",
                                    width: "25px",
                                    height: "25px",
                                    objectFit: "cover",
                                    marginRight: "20px",
                                    marginLeft: "0px",
                                  }}
                                  src={
                                    isValidUrl(userData?.profileImage)
                                      ? userData?.profileImage
                                      : UserImg
                                  }
                                  className="avtar-img"
                                  alt={fullName}
                                />
                                Profile
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/settings">
                                <img
                                  style={{
                                    marginRight: "20px",
                                  }}
                                  src={SettingIcon}
                                  alt={fullName}
                                />
                                Settings
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={handleLogout}
                                className="dropdown-item"
                                to="#"
                              >
                                <img
                                  src={Logout}
                                  style={{
                                    width: "28px",
                                    height: "30px",
                                    marginRight: "19px",
                                  }}
                                  alt={fullName}
                                />
                                Log Out
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </>
                    )}

                    {/* </div> */}

                    {user?.access?.token && isMobile ? ( // Check if location is not the home page ("/")
                      <>

                        <div className="headerRightCorner">
                          <Link className="downloadBtn" to="/">
                            Use App
                          </Link>


                          {(isSearchExpand &&
                            <div className="search-input">
                              <input
                                type="search"
                                placeholder="Search"
                              />
                            </div>

                          )}

                          <button className="searchIcon" onClick={handleExpandSearch}>
                            < img src={Search} alt="" />
                          </button>


                          <div onClick={toggleMenu}>
                            {isSidebarOpen ? (
                              <i className="fas fa-times" style={{ width: "12px" }}></i> // Cross icon when the menu is open
                            ) : (
                              <i className="fas fa-bars" style={{ width: "12px" }}></i> // Hamburger icon when the menu is closed
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="dropdown userProfileDropdown">
                          <Link
                            className=""
                            to={`/profile`}
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            {userData?.firstName + " " + userData?.lastName}

                            <img
                              style={{}}
                              src={
                                isValidUrl(userData?.profileImage)
                                  ? userData?.profileImage
                                  : UserImg
                              }
                              className="avtar-img"
                              alt={fullName}
                            />
                          </Link>

                          <ul className="dropdown-menu">
                            <li>
                              <Link className="dropdown-item" to={`/profile`}>
                                <img
                                  style={{
                                    border: "2px solid #898A8D",
                                    borderRadius: "50%",
                                    width: "25px",
                                    height: "25px",
                                    objectFit: "cover",
                                    marginRight: "20px",
                                    marginLeft: "0px",
                                  }}
                                  src={
                                    isValidUrl(userData?.profileImage)
                                      ? userData?.profileImage
                                      : UserImg
                                  }
                                  className="avtar-img"
                                  alt={fullName}
                                />
                                Profile
                              </Link>
                            </li>
                            <li>
                              <Link className="dropdown-item" to="/settings">
                                <img
                                  style={{
                                    marginRight: "20px",
                                  }}
                                  src="/static/media/settings.8a372ae9c2b9d2350b6c0b038dc154e8.svg"
                                  alt={fullName}
                                />
                                Settings
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={handleLogout}
                                className="dropdown-item"
                                to="#"
                              >
                                <img
                                  src={Logout}
                                  style={{
                                    border: "2px solid red",
                                    width: "20px",
                                    height: "20px",
                                    marginRight: "22px",
                                  }}
                                  alt={fullName}
                                />
                                Log Out
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Link className="px-4" to="/login">
                      Log In
                    </Link>
                    <Link className="px-3 bg-primary" to="/user-info">
                      Sign Up
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
      {isSidebarOpen && (
        <SideBarMain className="special-page-sidebar" isMobile={isMobile} />
      )}
    </>
  );
}
export default Header;
