import React, { useState } from "react";
import DefaultImgUser from "@assets/defaultUser.png";
import VideoImg from "@assets/video.jpg";
import {
  ThreeDots,
  BackButton,
  UserProfileList,
  SaveIcon,
  ShareIcon,
  LinkIcon,
  ReportIcon,
} from "@utils/defaultImages";
import "./GeneralPost.scss";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Comments from "components/Comments/Comments";
import { dateTimeFromNow } from "@utils/DateFormatter";
import { useDispatch, useSelector } from "react-redux";
import SliderComponent from "@components/Slider/Slider";
import { isValidUrl } from "@utils/validUrl";
import InstagramFeed from "@components/Skeleton/InstagramFeed";
import { useGetPostsDataQuery } from "@redux/services/crewService/allCrewService";
import { useMediaQuery } from "react-responsive";
import SpinnerLoader from "@components/Skeleton/SpinnerLoader";
import SignInModal from "@components/Modal/SignInModal";
import { setLoginModel } from "@redux/slices/authSlice/publicSlice";

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
};
export default function GeneralPost() {
  let getUserData;
  // media Query
  const isMobile = useMediaQuery({ maxWidth: 599 });
  const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 1023 });
  /**
   * USESTATES
   */
  const { postId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [openDropTitle, setOpenDropTitle] = useState(false);
  const [userData, setUserData] = useState({});

  /**
   * GET GLOBAL STATE
   */
   const userLoginToken = useSelector(
     (state) => state?.auth?.userAuthInfo?.access?.token
   );
  const user = useSelector((state) => state?.auth?.loginUserData);
  const showLoginModel = useSelector((state) => state.loginModel.showModel);
  /**
   * API CALLS
   */
  const { data: { data: post } = {}, isLoading } = useGetPostsDataQuery(postId);
  // console.log("crewId-=--=>", location);

  /**
   * FUNCTIONS
   */
  const doesAnyHistoryEntryExist = location.key !== "default";

  const getUserDataForPost = async (userId) => {
    try {
      const response = await getUserData(userId);
      setUserData(response?.data?.data?.user);
    } catch (error) {}
  };

  const listDropDown = [
    // {
    //   title: userData?.isFollowed ? "Unfollow" : "Follow",
    //   Image: FollowUnFllow,
    // },
    {
      title: "Profile",
      Image: UserProfileList,
      to: "/setting",
    },
    {
      title: "Save",
      Image: SaveIcon,
      to: "/setting",
    },
    {
      title: "Share",
      Image: ShareIcon,
      to: "/setting",
    },
    {
      title: "Link",
      Image: LinkIcon,
      to: "/setting",
    },
    {
      title: "Report",
      Image: ReportIcon,
      to: "/setting",
    },
  ];
  const handleList = (title) => {
    if (title === "Unfollow" || title === "Follow") {
      // followUnfollowUser();
    }
  };
  const handleToggle = () => {
  dispatch(setLoginModel());
}
  const dropDownTitle = ["Save", "Share", "Link", "Report"];
  return (
    <>
      {isLoading ? (
        isMobile || isTablet ? (
          <SpinnerLoader />
        ) : (
          <InstagramFeed />
        )
      ) : (
        <div className="px-3">
          <div className="post-title general-post-header">
            {doesAnyHistoryEntryExist && userLoginToken && (
              <Link onClick={() => navigate(-1)}>
                <BackButton />
              </Link>
            )}

            <div>
              <div className="avtar-in">
                <Link
                  to={
                    user?._id === post?.userId
                      ? `/profile`
                      : `/profile/${post?.userId}`
                  }
                  className="profile-link"
                  style={{ flex: "0 0 60px" }}
                >
                  <img
                    src={
                      isValidUrl(post?.user?.profileImage)
                        ? post?.user?.profileImage
                        : DefaultImgUser
                    }
                    alt={post?.user?.username}
                    className="main-img"
                  />
                </Link>
                <div>
                  <div className="name">
                    <Link
                      to={
                        user?._id === post?.userId
                          ? `/profile`
                          : `/profile/${post?.userId}`
                      }
                      className="profile-link"
                    >
                      {(post?.user?.username && post?.user?.username) || ""}
                    </Link>
                  </div>
                  <div className="d-flex align-items-center">
                    {dateTimeFromNow(post?.createdAt)}
                  </div>
                </div>
              </div>
            </div>
            <>
              <div
                role="button"
                // onClick={() => dispatch(setLoginModel())}
              >
                <ThreeDots />
              </div>
              {openDropTitle && (
                <>
                  <ul className="dropdown-menu-general">
                    {listDropDown?.map(({ title, to, Image }) => {
                      return (
                        <li key={title}>
                          <div
                            // to={`/profile/${postId}`}
                            className="dropdown-item"
                            onClick={() => handleList(title)}
                            style={{
                              color: dropDownTitle?.includes(title)
                                ? "#8E8E93"
                                : "",
                              cursor: dropDownTitle?.includes(title)
                                ? "not-allowed"
                                : "pointer",
                            }}
                          >
                            <Image
                              style={{
                                width: "25px",
                                height: "25px",
                                objectFit: "cover",
                                marginRight: "20px",
                                marginLeft: "0px",
                              }}
                              className="avtar-img"
                              alt="img78"
                            />
                            {title}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
            </>
            {/* {isFollow ? (
                <div className="follow-btn">Edit Profile</div>
              ) : isDisableFollowUserBtn ? (
                <div className="follow-btn">...</div>
              ) : (
                <div className="follow-btn" onClick={followUnfollowUser}>
                  {userData?.isFollowed ? "Following" : "Follow"}
                </div>
              )} */}
          </div>
          {/* {post?.title && (
            <div className="show-more-description mb-3">
              <ReadMore sliceText={200}>{post?.title}</ReadMore>
            </div>
          )} */}

          {post?.media?.length > 1 ? (
            <div className="borderforAll">
              <SliderComponent settings={settings}>
                {post?.media?.map((mediaData, index) => (
                  <React.Fragment key={`${index}-of`}>
                    {mediaData?.type === "1" &&
                    mediaData?.path?.includes("https") ? (
                      <>
                        <img
                          src={mediaData.path}
                          alt=""
                          className="vid-img-how"
                        />
                      </>
                    ) : mediaData?.type === "2" &&
                      mediaData?.path?.includes("https") ? (
                      <video
                        src={mediaData?.path}
                        autoPlay={true}
                        height="620"
                        disablePictureInPicture={true}
                        controlsList="nodownload noplaybackspeed noplaybackrate"
                        controls
                        muted={true}
                      />
                    ) : null}
                  </React.Fragment>
                ))}
              </SliderComponent>
            </div>
          ) : (
            <>
              {post?.media?.map((mediaData, index) => (
                <>
                  {mediaData?.type === "1" &&
                  mediaData?.path?.includes("https") ? (
                    <div className="borderforAll">
                      <img
                        src={mediaData?.path ? mediaData.path : VideoImg}
                        alt={post?.user?.username}
                        className="vid-img-how mt-3"
                      />
                    </div>
                  ) : mediaData?.type === "2" &&
                    mediaData?.path?.includes("https") ? (
                    <div className="borderforAll">
                      {/* <ReactPlayer
                                    playing={played}
                                    controls={true}
                                    url={mediaData.path}
                                    style={{ maxHeight: "600pc" }}
                                    width="45vwvw"
                                    height="60vh"
                                    ref={videoRef}
                                    playsinline={true}
                                  /> */}
                      <video
                        src={mediaData?.path}
                        autoPlay={true}
                        height="620"
                        disablePictureInPicture={true}
                        controlsList="nodownload noplaybackspeed noplaybackrate"
                        controls
                        muted={true}
                      />
                    </div>
                  ) : null}
                </>
              ))}
            </>
          )}

          {/* {post?.description && (
            <div className="show-more-title">
              <ReadMore sliceText={250}>{post?.description}</ReadMore>
            </div>
          )} */}
          <Comments
            post={post}
            postId={post?._id}
            isComponent={true}
            // isTablet={isTablet}
          />
        </div>
      )}
      {/* {!(isMobile || isTablet) && post && (
        <div>
          <CrewUserProfile user={user} crewId={post?.crewId} />
        </div>
      )} */}
      {showLoginModel && (
        <SignInModal
          open={showLoginModel}
          closeModal={() => dispatch(setLoginModel(!showLoginModel))}
        />
      )}
    </>
  );
}
