import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./WrapperModal.scss";
import { ReactComponent as Close } from "@assets/close.svg";
import { ReactComponent as Back } from "@assets/backButton.svg";
import { useLocation } from "react-router-dom";

const WrapperModal = React.memo(({ open, closeModal, children, style }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [open]);

  const dynamicClassName = pathname.includes("/login")
    ? "modal__close modal-login-profile"
    : pathname.includes("main-feed") ||
      pathname.includes("followers") ||
      pathname.includes("followings") ||
      pathname.includes("/")
    ? "modal__close modal-user-profile"
    : "other-class";

  if (!open) return null;

  return ReactDOM.createPortal(
    <div>
      <div className="overlay" onClick={closeModal} />
      <div className="wrapper-modal user-profile-modal" style={style || {}}>
        <button className={dynamicClassName} onClick={closeModal}>
          {pathname.includes("main-feed") ||
          pathname.includes("followers") ||
          pathname.includes("followings") ||
          pathname.includes("/") ||
          pathname.includes("/login") ? (
            <Close />
          ) : (
            <Back />
          )}
        </button>
        {children}
      </div>
    </div>,
    document.body
  );
});

export default WrapperModal;
