import React, { useState } from "react";
import "@containers/UserProfile/subComponents/Followerslist.scss";
import ModeratorOfListById from "./TabSwitch/ModeratorOfListById";
import MemberOfListById from "./TabSwitch/MemberOfListById";
import YourCrewsTabById from "./YourCrewsTabById";

export default function YourCrewsById() {
  const [tabOpen, setTabOpen] = useState(1);
  return (
    <div className="crew-post-Wrapper follow-followers">
      <div>
        <YourCrewsTabById tabOpen={tabOpen} setTabOpen={setTabOpen} />
        {tabOpen === 1 && <ModeratorOfListById />}
        {tabOpen === 2 && <MemberOfListById />}
      </div>
    </div>
  );
}
