import React from "react";
import WrapperModal from "./WrapperModal";
import "./ThreeDotsModal.scss";

// Import your base64 image string
import ShareImage from "@assets/ThreeDotsImages/share.png";
import MembershipBoard from "@assets/ThreeDotsImages/membership.png";
import Settings from "@assets/ThreeDotsImages/Settings.png";
import Edit from "@assets/ThreeDotsImages/edit.png";
import Invite from "@assets/ThreeDotsImages/invite.png";

const ThreeDotsModal = React.memo(({ closeModal }) => {
  const handleClick = (key) => {
    closeModal(key);
  };

  return (
    <div className="ThreeDotsModal">
      <div className="image-paragraph" onClick={() => handleClick("share")}>
        <img src={ShareImage} alt="Share" />
        <p>Share</p>
      </div>
      <div
        className="image-paragraph"
        onClick={() => handleClick("membership")}
      >
        <img src={MembershipBoard} alt="MembershipBoard" />
        <p>Membership Board</p>
      </div>
      <div className="image-paragraph" onClick={() => handleClick("settings")}>
        <img src={Settings} alt="Settings" />
        <p>Settings</p>
      </div>
      <div className="image-paragraph" onClick={() => handleClick("edit")}>
        <img src={Edit} alt="Edit" />
        <p>Edit</p>
      </div>
      <div className="image-paragraph" onClick={() => handleClick("invite")}>
        <img src={Invite} alt="Invite" />
        <p>Invite people to Carvonix</p>
      </div>
    </div>
  );
});

export default ThreeDotsModal;
