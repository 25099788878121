import React from "react";
import { Carousel } from "react-responsive-carousel";

const SliderShow = ({ media }) => {
    return (
      <Carousel>
        {media?.map((mediaData,index) => (
          <div key={index}>
            <img src={mediaData?.path} alt="" className="vid-img-how" />
          </div>
        ))}
      </Carousel>
    );
};

export default SliderShow;
