import {
  Comments_Img,
  Comments_Img_mob,
  New_Comments,
  New_Comments_mob,
  StoryComments,
} from "@utils/defaultImages";
import useMediaQueryHook from "@utils/hooks/useMediaQueryHook";
import React from "react";

const SeamlessEvent = () => {
   const { isMobile, isTablet, isMobTab } = useMediaQueryHook();
  const storiesDetail = [
    {
      heading: "Quick, casual moments",
      paragraph: "Stories disappear after 24 hours.",
    },
    {
      heading: "Interact & react",
      paragraph:
        "React to a story or reply with a DM and start a conversation.",
    },
    {
      heading: "Spotlight your best memories",
      paragraph:
        "Pin your favorite stories to spotlight to keep them on your profile permanently.",
    },
  ];

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 updates">
            <h1>Share Everyday Moments With Stories</h1>
            <p>
              Effortlessly share quick updates of your latest mods, track day
              clips, or that perfect sunset drive.
            </p>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-lg-4 col-md-6 col-sm-6 col-6 new-comm">
            {isMobile || isTablet || isMobTab ? (
              <img src={Comments_Img_mob} alt="Comments_Img_mob" />
            ) : (
              <img src={Comments_Img} alt="comments" />
            )}
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-6 comments-img">
            {(isMobile || isTablet || isMobTab) ? (
              <img src={New_Comments_mob} alt="New_Comments_mob" />
            ) : (
              <img src={New_Comments} alt="comments" />
            )}
          </div> */}
          <div className="col-lg-8 col-md-12 col-sm-12 col-12 comments-img">
            {/* {isMobile || isTablet || isMobTab ? (
              <img src={New_Comments_mob} alt="New_Comments_mob" />
            ) : ( */}
            <img src={StoryComments} alt="comments" />
            {/* )} */}
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12 text-comments d-flex flex-column justify-content-center">
            {storiesDetail.map(({ heading, paragraph }) => (
              <>
                <h4>{heading}</h4>
                <p className="pb-4">{paragraph}</p>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SeamlessEvent;
