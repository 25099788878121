import React from "react";
import WrapperModal from "./WrapperModal";
import ConfirmPassword from "@containers/Auth/ForgotPassword/ConfirmPassword";

const ConfirmPasswordModal = React.memo(
  ({ openConfirmPswrd, closeModal, userData, checkEmailModal, resetModal }) => {
    return (
      <div id="modal">
        <WrapperModal
          open={openConfirmPswrd}
          closeModal={closeModal}
          style={{
            top: "50%",
            transform: "translateY(-50%)",
            left: 0,
            right: 0,
            margin: "0 auto",
          }}
        >
          <ConfirmPassword
            userData={userData}
            closeModal={closeModal}
            checkEmailModal={closeModal}
            resetModal={resetModal}
          />
        </WrapperModal>
      </div>
    );
  }
);

export default ConfirmPasswordModal;
