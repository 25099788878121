import React from "react";
import WrapperModal from "./WrapperModal";
import ShowLikedUserModal from "./ShowLikedUserModal";

const ReactionShowModal = React.memo(({ open, closeModal, modelId }) => {
  return (
    <div id="modal">
      <WrapperModal
        open={open}
        closeModal={closeModal}
        style={{
          top: "50%",
          transform: "translateY(-50%)",
          left: 0,
          right: 0,
          margin: "0 auto",
          backgroundColor: "#121313",
        }}
      >
        <ShowLikedUserModal
          closeModal={closeModal}
          open={open}
          modelId={modelId?._id}
        />
      </WrapperModal>
    </div>
  );
});

export default ReactionShowModal;
