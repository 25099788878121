import React, { useState, useRef } from "react";
import "../FeedsPage.scss";
import {
  BannerImg,
  DefaultImg,
  DefaultUserImg,
  Demo_Car,
  DummyStory,
} from "@utils/defaultImages";
import { useFeedStoriesQuery } from "@redux/services/mainFeed/feedService";
import { isValidUrl } from "@utils/validUrl";
import { Link, useNavigate } from "react-router-dom";

const Stories = ({ feedStories }) => {
  const navigate = useNavigate();
  /**
   * API CALLS
   */

  /**
   * FUNCTIONS
   */

  const homeStories = feedStories?.filter((item) => item?.stories?.length > 0);
  const handleNavigate = (len) => {
    if (len) {
      navigate("/stories-list");
    }
  };
  return (
    <div className="story-container">
      {homeStories?.map((story) => {
        return (
          // story?.stories?.length > 0 && (
          <div
            key={story.id}
            className={`story-div ${story?.stories?.length > 0 && "pointer"}`}
            // onClick={() => togglePopup(story.id)}
            onClick={() => handleNavigate(story?.stories?.length)}
          >
            <div className={`story-media`}>
              <img
                src={
                  isValidUrl(story?.stories[0]?.media?.thumbnail)
                    ? story?.stories[0]?.media?.thumbnail
                    : isValidUrl(story?.stories[0]?.media?.path)
                    ? story?.stories[0]?.media?.path
                    : DefaultImg
                }
                alt="Story"
              />
            </div>
            <div className="user-profile-pic">
              <img
                src={
                  isValidUrl(story.profileImage)
                    ? story.profileImage
                    : DefaultUserImg
                }
                alt={story.username}
                height="38px"
                width="38px"
              />
            </div>
            <div className="user-name">{story.username}</div>
          </div>
          // )
        );
      })}
    </div>
  );
};

export default Stories;
