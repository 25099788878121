import React from "react";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "@components/ScrollToTop";
import "./css/App.css";
import "./responsive.scss";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./css/ui.scss";
import Router from "@routes/router";

{
  /*
                        
format 1= review post componentid
format 2= how to 
format 3= simple post

                        */
}
function App() {
  // var domain = window.location.pathname;
  // console.log("domain", domain);
  // if (domain.endsWith("/")) {
  //   domain = domain.slice(0, -1);
  // }
  // if (domain === "/stories-list") {
  //   document.body.style.overflow = "hidden";
  // } else {
  //   document.body.style.overflow = "auto";
  // }
  return (
    <>
      <ScrollToTop />
      <Router />
      <ToastContainer style={{ zIndex: "999999" }} />
    </>
  );
}

export default React.memo(App);
