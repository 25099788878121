import React from "react";
import WrapperModal from "./WrapperModal";

// import YourCrews from "@containers/UserProfileById/subComponents/YourCrewsById";
import YourCrewsById from "@containers/UserProfileById/subComponents/YourCrewsById";

const YourCrewsModalById = React.memo(({ open, closeModal, navigateVal }) => {
  return (
    <div id="modal">
      <WrapperModal
        open={open}
        closeModal={closeModal}
        style={{
          top: "50%",
          transform: "translateY(-50%)",
          left: 0,
          right: 0,
          margin: "0 auto",
          backgroundColor:"#121313"
        }}
      >
        <YourCrewsById/>
      </WrapperModal>
    </div>
  );
});

export default YourCrewsModalById;
