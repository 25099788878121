import React from "react";
import Skeleton from "react-loading-skeleton";
import "./StoriesSkeleton.scss";
import useMediaQueryHook from "@utils/hooks/useMediaQueryHook";

const StoriesSkeleton = () => {
  const { isMobTab, isMobile, isMobileSmall, isTablet, isTabScr } =
    useMediaQueryHook();
  const widthHeight = {
    container_Width: isMobile ? 384 : isMobileSmall ? 320 : 482,
    container_Height: isMobileSmall ? 600 : isMobile ? 790  : 853,
    wrp_footer: isMobileSmall ? 280 : 350,
    footer_heading: isMobileSmall ? 200 : 300,
  };

  const { container_Width, container_Height, wrp_footer, footer_heading } =
    widthHeight;
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 d-flex justify-content-end">
          <div className="story-skeleton ">
            <Skeleton height={container_Height} width={container_Width} />
            <div className="header-skeleton">
              <div className="d-flex align-items-center gap-2">
                <Skeleton
                  circle
                  width={50}
                  height={50}
                  baseColor="#ababab"
                  highlightColor="#fafafa"
                />
                <div>
                  <Skeleton
                    width={100}
                    height={10}
                    baseColor="#ababab"
                    highlightColor="#fafafa"
                  />
                  <Skeleton
                    width={70}
                    height={10}
                    baseColor="#ababab"
                    highlightColor="#fafafa"
                  />
                </div>
              </div>
            </div>
            <div className="footer-skeleton">
              <div className="wrap-footer">
                <Skeleton
                  width={wrp_footer}
                  height={130}
                  borderRadius={7}
                  baseColor="#525252"
                  highlightColor="#fafafa"
                />
                <div className="content-footer ">
                  <div className="d-flex align-items-center gap-2">
                    <Skeleton
                      circle
                      width={50}
                      height={50}
                      baseColor="#ababab"
                      highlightColor="#fafafa"
                    />
                    <div>
                      <Skeleton
                        width={100}
                        height={10}
                        baseColor="#ababab"
                        highlightColor="#fafafa"
                      />
                      <Skeleton
                        width={70}
                        height={10}
                        baseColor="#ababab"
                        highlightColor="#fafafa"
                      />
                    </div>
                  </div>
                  <div className="">
                    <Skeleton
                      width={footer_heading}
                      height={10}
                      baseColor="#ababab"
                      highlightColor="#fafafa"
                    />
                    <Skeleton
                      width={footer_heading}
                      height={10}
                      baseColor="#ababab"
                      highlightColor="#fafafa"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoriesSkeleton;
