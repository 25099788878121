import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import logo from "@assets/authImgs/logo.svg";
import { ReactComponent as LoginGoogle } from "@assets/login-google.svg";
import { ReactComponent as LoginFacebook } from "@assets/LoginFacebook.svg";
import { ReactComponent as LoginApple } from "@assets/LoginApple.svg";
import "./Login.scss";
import ButtonLoader from "@components/Loaders/ButtonLoader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useLoginUserMutation } from "@redux/services/auth/loginService.js";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  setAuth,
  setLoginData,
} from "@redux/slices/authSlice/loginSlice.js";
import ResetModal from "@components/Modal/ResetModal.js";

const google_client_id = process.env.REACT_APP_GG_APP_ID || "";
const facebook_app_id = process.env.REACT_APP_FACEBOOK_APP_ID || "";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

function Login() {
  const dispatch = useDispatch();
  const [loginUser] = useLoginUserMutation();
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (values) => {
    try {
      const loginResponse = await loginUser(values).unwrap();
      dispatch(setAuth(loginResponse?.data?.tokens));
      dispatch(setLoginData(loginResponse?.data?.user));
      // console.log("refreshResult---??", loginResponse);
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  const handleGoogleLogin = useCallback(async (data) => {
    const sendData = {
      firstName: data?.given_name,
      lastName: data?.family_name,
      email: data?.email,
      type: 1,
      token: data?.access_token,
      googleId: data?.sub,
      profileImage: data?.picture,
    };
    // dispatch(socialLogin(sendData));
  }, []);

  const handleFacebookLogin = useCallback(async (facebookResponse) => {
    const { provider, data } = facebookResponse;
  }, []);

  const onLoginStart = useCallback(() => {
    alert("login start");
  }, []);

  const onLogoutSuccess = useCallback(() => {
    alert("logout success");
  }, []);

  const handleForgotPassword = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <div>
      <div className="login-wrapper">
        <div className="wrapper">
          <Link to="/">
            <img src={logo} alt="Carvonix Logo" className="logo" />
          </Link>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="form-group mb-4">
                  <Field
                    type="text"
                    name="email"
                    placeholder="Email"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger error-message"
                  />
                </div>
                <div className="form-group position">
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    className="form-control"
                  />
                  <div
                    className="eye-icon"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <i className="fas fa-eye-slash"></i>
                    ) : (
                      <i className="fas fa-eye"></i>
                    )}
                  </div>
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-danger error-message"
                  />
                </div>
                <div className="btn-pass">
                  <button
                    type="button"
                    className="btn-forgot"
                    onClick={handleForgotPassword}
                  >
                    <h1 className="forgot">Forgot password?</h1>
                  </button>
                </div>

                <button
                  type="submit"
                  className="login-btn"
                  style={{ outline: "none" }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <div style={{ padding: "10px" }}>
                      <ButtonLoader />
                    </div>
                  ) : (
                    "Log in"
                  )}
                </button>
                {open && (
                  <ResetModal open={open} closeModal={() => setOpen(false)} />
                )}
              </Form>
            )}
          </Formik>
          <div className="or">OR</div>
          <ul className="signup-options">
            <LoginSocialGoogle
              client_id={google_client_id}
              scope="openid profile email"
              onResolve={({ data }) => handleGoogleLogin(data)}
              onReject={(provider, data) => console.log(provider, data)}
            >
              <li>
                <LoginGoogle />
              </li>
            </LoginSocialGoogle>

            <LoginSocialFacebook
              appId={facebook_app_id}
              onResolve={handleFacebookLogin}
              fieldsProfile={
                "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
              }
              onLoginStart={onLoginStart}
              onLogoutSuccess={onLogoutSuccess}
              redirect_uri="https:localhost:8081/user-profile"
              onReject={(err) => {
                console.log(err);
              }}
            >
              <li>
                <LoginFacebook />
              </li>
            </LoginSocialFacebook>
            <li>
              <LoginApple />
            </li>
          </ul>
          <div className="signup-text">
            Dont have an account? <Link to="/user-info">Sign up</Link>
          </div>
          <div className="terms-text">
            By signing up you agree to our <Link to="#">Terms</Link>,{" "}
            <Link to="#">Privacy Policy</Link>, and{" "}
            <Link to="#">Cookie Use</Link>.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
