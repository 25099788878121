import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Mousewheel, Pagination } from "swiper/modules";
import styled from "styled-components";
import useMediaQueryHook from "@utils/hooks/useMediaQueryHook";
import { Player } from "@lottiefiles/react-lottie-player";
import { Crew, Home, Mods, Profile } from "@utils/defaultImages";

const StyledIcon = styled.div`
  .logo-icon {
    path {
      fill: #8e8e93;
    }
  }

  &.actived .logo-icon {
    path {
      fill: #ffffff;
    }
  }
`;

const Communities = ({ activeLink, setActiveLink, multiplePost }) => {
  const { isMobile, isTablet, isMobTab } = useMediaQueryHook();
  const swiperRef = useRef(null);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [isAtStart, setIsAtStart] = useState(true);
  useEffect(() => {
    const handleScroll = (e) => {
      if (isAtEnd && e.deltaY > 0) {
        swiperRef.current.swiper.mousewheel.disable();
      } else if (isAtStart && e.deltaY < 0) {
        swiperRef.current.swiper.mousewheel.disable();
      } else {
        swiperRef.current.swiper.mousewheel.enable();
      }
    };

    window.addEventListener("wheel", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, [isAtEnd, isAtStart]);

  const handleSlideChange = (swiper) => {
    setActiveLink(swiper.activeIndex);
    setIsAtEnd(swiper.isEnd);
    setIsAtStart(swiper.isBeginning);

    // Enable mousewheel if not at the end or start
    if (!swiper.isEnd && !swiper.isBeginning) {
      swiperRef.current.swiper.mousewheel.enable();
    }
  };

  return (
    <>
      <div className="slider-communities">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center">
              <div className="active-navLink my-5">
                {[Home, Crew, Mods, Profile].map((Icon, index) => (
                  <StyledIcon
                    key={index}
                    className={`icons ${activeLink === index ? "actived" : ""}`}
                    onClick={() => {
                      setActiveLink(index);
                      swiperRef.current.swiper.slideTo(index);
                    }}
                    style={{
                      cursor: "pointer",
                      borderBottom:
                        activeLink === index ? "3px solid #387AFF" : "unset",
                      width: "100px",
                    }}
                  >
                    <Icon className="logo-icon" />
                    <span
                      className="pt-2 pb-3"
                      style={{
                        color: activeLink !== index ? "#8E8E93" : "#ffffff",
                        fontWeight: activeLink !== index ? "300" : "600",
                      }}
                    >
                      {["Home", "Crew", "Mods", "Profile"][index]}
                    </span>
                  </StyledIcon>
                ))}
              </div>
            </div>
          </div>
          <Swiper
            ref={swiperRef}
            modules={[Mousewheel, Pagination]}
            direction="horizontal"
            slidesPerView={1}
            mousewheel={true}
            onSlideChange={handleSlideChange}
          >
            {multiplePost.map((post, index) => (
              <SwiperSlide key={index}>
                <div
                  className={`container-share`}
                  style={{ display: activeLink === index ? "block" : "none" }}
                >
                  <div className="row">
                    <div
                      className={`col-lg-6 col-md-12 col-sm-12 col-12 home-post ${
                        isMobile || isTablet || isMobTab ? "order-2" : "mb-5"
                      }`}
                    >
                      {index === 1 ? (
                        <Player
                          src={post.LinkImg}
                          className="player"
                          loop
                          autoplay
                          style={{ height: !isMobile ? "500px" : "100%" }}
                        />
                      ) : (
                        <img
                          src={post.LinkImg}
                          alt="post"
                          className="img-fluid"
                        />
                      )}
                    </div>
                    <div
                      className={`col-lg-6 col-md-12 col-sm-12 col-12 d-flex flex-column justify-content-center wrap-links mb-5 ${
                        isMobile || isTablet || isMobTab ? "order-1" : ""
                      }`}
                    >
                      <div className="share">
                        <h3>{post.title}</h3>
                        <p>{post.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Communities;
