import React from "react";
import Form from "../../../Forms/Formik/Form";
import RenderError from "../../../Error/RenderError";

const CommentForm = ({
  onSubmit = () => {},
  formik,
  submitText,
  disableSubmit,
  isDisableBtn,
  onChange = () => {},
  value,
  onBlur = () => {},
  touched,
  message,
  className,
  autoFocus,
}) => {
  return (
    <div
      className="comment-box"
    >
      <Form
        onSubmit={onSubmit}
        formik={formik}
        submitText={submitText}
        className={className}
        disableSubmit={disableSubmit}
        isDisableBtn={isDisableBtn}
      >
        <textarea
          ref={autoFocus}
          placeholder="Add a comment..."
          name="commentText"
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          autoFocus={autoFocus}
        />
        {touched.commentText && <RenderError message={message} />}
      </Form>
    </div>
  );
};

export default CommentForm;
