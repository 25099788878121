import React from "react";

import "./ComponentPage.scss";
import PartsSlider from "./components/PartsSlider";
import { useParams } from "react-router";
import UserProfileComponent from "@components/UserProfileComponent";
import { useGetReviewDataByIdQuery } from "@redux/services/userProfile/BuildComponentService";
import { useMediaQuery } from "react-responsive";

function ComponentPage() {
  // media Query
  const isMobile = useMediaQuery({ maxWidth: 599 });
  const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 1023 });
  const { componentId } = useParams();

  /**
   * API CALLS
   */
  const { data: { data: reviewData } = {}, isLoading } =
    useGetReviewDataByIdQuery(componentId);
  console.log("Component---????", reviewData);

  const listDropDown = [
    // {
    //   //  userData?.isFollowed ? "Unfollow" :
    //   title: "Follow",
    //   Image: FollowUnFllow,
    // },
    {
      title: "Profile",
      to: "/setting",
    },
    {
      title: "Save",
      to: "/setting",
    },
    {
      title: "Share",
      to: "/setting",
    },
    {
      title: "Link",
      to: "/setting",
    },
    {
      title: "Report",
      to: "/setting",
    },
  ];
  const dropDownTitle = ["Save", "Share", "Link", "Report"];
  return (
    <>
      <div>
        <PartsSlider reviewData={reviewData} isLoading={isLoading} />
      </div>
      {!(isMobile || isTablet) && (
        <div>
          <UserProfileComponent reviewData={reviewData} />
        </div>
      )}
    </>
  );
}
export default ComponentPage;
