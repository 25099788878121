import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./LikedUsername.scss";
import LikedImages from "@containers/MainFeed/components/LikedImages";
import ReactionShowModal from "../Modal/ReactionShowModal";
import { useDispatch, useSelector } from "react-redux";
import { setLoginModel } from "@redux/slices/authSlice/publicSlice";

const LikedUsername = React.memo(({ reacted, reactionCount, isLiked }) => {
  const dispatch = useDispatch();

  /**
   * GET GLOBAL STATE
   */
  const userLoginToken = useSelector(
    (state) => state?.auth?.userAuthInfo?.access?.token
  );
  const showLoginModel = useSelector((state) => state.loginModel.showModel);
  const [openLikeModal, setOpenLikeModal] = useState(false);
  // console.log("reacted----???", reacted, reactionCount, isLiked);
  function getLikeModel() {
    if (userLoginToken) {
      setOpenLikeModal(!openLikeModal);
    } else {
      dispatch(setLoginModel(!showLoginModel));
    }
  }
  return (
    <div>
      <Link onClick={getLikeModel}>
        <div className="wrapper-data">
          <div className="liked-in-page">
            {reactionCount > 0 && (
              <LikedImages
                reactUser={reacted?.reactedtUserList}
                isLiked={isLiked}
              />
            )}
          </div>

          <div
            className="name"
            style={{
              wordBreak: "unset",
              marginTop: "unset",
            }}
          >
            <div className="username-liked">
              {/* {reactionCount > 0 &&
                (isLiked ? (
                  <>
                    Liked by <strong>You</strong>{" "}
                    {reactionCount >= 2
                      ? " and " + (reactionCount - 1) + " others"
                      : ""}{" "}
                  </>
                ) : (
                  <>Liked by {reactionCount} others</>
                ))} */}
              {/* {reactionCount > 0 && isLiked ? (
                <>
                  Liked by <strong>You</strong>{" "}
                  {reactionCount === 2
                    ? " and " +
                      (reacted?.reactedtUserList[1]?.username ||
                        reacted?.reactedtUserList[0]?.username)
                    : reactionCount > 2
                    ? " and " + (reactionCount - 1) + " others"
                    : ""}{" "}
                </>
              ) : reactionCount === 1 ? (
                <>Liked by {reacted?.reactedtUserList[0]?.username}</>
              ) : !isLiked && reactionCount === 1 ? (
                <>Liked by {reacted?.reactedtUserList[1]?.username}</>
              ) : (
                <>Liked by {reactionCount} others</>
              )} */}
              {reactionCount > 0 && isLiked ? (
                <>
                  Liked by <strong>You</strong>{" "}
                  {reactionCount === 2 ? (
                    <>
                      {" "}
                      and{" "}
                      <strong>
                        {reacted?.reactedtUserList[1]?.username ||
                          reacted?.reactedtUserList[0]?.username}{" "}
                      </strong>
                    </>
                  ) : reactionCount > 2 ? (
                    " and " + (reactionCount - 1) + " others"
                  ) : (
                    ""
                  )}{" "}
                </>
              ) : reactionCount === 1 ? (
                <>
                  Liked by{" "}
                  <strong>
                    {reacted?.reactedtUserList[1]?.username ||
                      reacted?.reactedtUserList[0]?.username}
                  </strong>
                </>
              ) : reactionCount === 2 &&
                reacted?.reactedtUserList?.length === 3 &&
                isLiked === false ? (
                <>
                  Liked by{" "}
                  <strong>{reacted?.reactedtUserList[1]?.username}</strong> and{" "}
                  <strong> {reacted?.reactedtUserList[2]?.username}</strong>
                </>
              ) : reactionCount === 2 ? (
                <>
                  Liked by{" "}
                  <strong> {reacted?.reactedtUserList[0]?.username}</strong> and{" "}
                  <strong>{reacted?.reactedtUserList[1]?.username}</strong>
                </>
              ) : reactionCount > 1 ? (
                <>
                  Liked by{" "}
                  <strong>{reacted?.reactedtUserList[0]?.username}</strong> and{" "}
                  {reactionCount - 1} others
                </>
              ) : (
                <>No likes yet</>
              )}
            </div>
          </div>
        </div>
      </Link>
      <ReactionShowModal
        open={openLikeModal}
        closeModal={() => setOpenLikeModal(false)}
        modelId={reacted}
      />
    </div>
  );
});

export default LikedUsername;
