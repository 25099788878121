import React from "react";
import ContentLoader from "react-content-loader";

const BuildSkeleton = (props) => (
  <ContentLoader
    viewBox="0 0 500 420"
    height={220}
    width={250}
    speed={2}
    backgroundColor="#ababab"
    foregroundColor="#fafafa"
    {...props}
  >
    <rect x="16" y="17" rx="0" ry="0" width="360" height="200" />
  </ContentLoader>
);

export default BuildSkeleton;
