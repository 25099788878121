import React from "react";
import VideoPlayer from "@components/VideoPlayer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import Bolt from "@assets/Bolt.svg";
import Example from "@assets/example.svg";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Navigation, Pagination } from "swiper/modules";
import { UserImage } from "@utils/defaultImages";
import { isValidUrl } from "@utils/validUrl";
import { useMediaQuery } from "react-responsive";
const Spotlight = ({ spotlight }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 599 });
  const isTablet = useMediaQuery({ minWidth: 600, maxWidth: 991 });
  const evenArrays = () => {
    let arr = [];

    if (spotlight?.length % 2 !== 0) {
      arr = spotlight?.slice(1);
    }
    if (spotlight?.length % 2 == 0) {
      arr = spotlight;
    }
    return arr;
  };
  const handleNavigate = (spotlightData) => {
    navigate("/spotlight-list", { state: { spotlight: spotlightData } });
  };

  return (
    <>
      <div className="spotlight">
        <div className="spotlight-header">
          <img src={Bolt} alt="image" />
          <h1>Spotlights</h1>
        </div>
        <div className="spotlight-items row">
          {evenArrays()?.map((spotlight) => {
            return (
              <div
                className="spotlight-item col-lg-3 col-md-6 col-sm-6 col-6"
                style={{ marginTop: isMobile || isTablet ? "10px" : "0px" }}
                onClick={() => handleNavigate(spotlight)}
              >
                <img
                  src={
                    isValidUrl(spotlight?.media?.thumbnail)
                      ? spotlight?.media?.thumbnail
                      : UserImage
                  }
                  className="thumbnail"
                />
                <div className="user">
                  <img
                    src={spotlight.user.profileImage || UserImage}
                    alt="image"
                  />
                  <p>{spotlight.user.username || "username"}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Spotlight;
