import {
  RealChat,
  Ride,
  Trending,
  Truck,
  Weekend,
  Weekend_Drive,
  Weekend_Drive_Mob,
  Local,
  Talk,
  MeetUp,
  New,
  Questions,
  Business,
} from "@utils/defaultImages";
import useMediaQueryHook from "@utils/hooks/useMediaQueryHook";
import React from "react";

const NewCommunities = () => {
  const { isMobile, isTablet, isMobTab, isMobileSmall } = useMediaQueryHook();

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 communities">
            <h1>Find New Communities</h1>
            <p className="pt-3">
              The Crew section allows users to find their community, niche, etc.
            </p>
            <div className="features py-4">
              {[Trending, Local, Talk, MeetUp, New, Questions, Business].map(
                (CommunitiesIcon, index) => (
                  <div className="text-svg">
                    <CommunitiesIcon />
                    <p>
                      {
                        [
                          "Trending",
                          "Local To You",
                          "Auto Talk",
                          "Meetup",
                          "New",
                          "Questions & Answers",
                          "Business",
                        ][index]
                      }
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 instant my-5">
            <div className="real-time">
              <h1 className="py-3">
                Real-time messaging for instant communication{" "}
              </h1>
              <p className="pb-4">
                Drive conversations, share experiences, and rev up your passion
                for all things automotive.
              </p>
            </div>
            <div className="chat-img">
              <img src={RealChat} alt="RealChat" />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 my-5">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 crews-public">
              <div className="public">
                <h3>Public Crews</h3>
                <p>
                  Connect with a broader community around specific interests,
                  models, or regions.
                </p>
              </div>
              <div className="ride-die">
                <img src={Truck} alt="Truck" />
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 crews-public mt-4">
              <div className="public">
                <h3>Private Crews</h3>
                <p>
                  Unlock exclusive communities. Create your private haven or
                  join others – where passion meets exclusivity.
                </p>
              </div>
              <div className="ride-die">
                <img src={Ride} alt="Ride" />
              </div>
            </div>
          </div>
        </div>
        <div className="organize mb-5">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex flex-column justify-content-center">
              <div className="organize-event">
                <h3>Organize Events</h3>
                <p>
                  Schedule scenic stops, share routes, and experience the joy of
                  driving in a convoy with fellow enthusiasts.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex">
              <div
                className={`col-lg-6 col-md-12 col-sm-12 col-12 d-flex ${
                  (isMobile || isMobTab || isMobileSmall) &&
                  "justify-content-center"
                }`}
                style={{
                  textAlign: (isMobile || isTablet || isMobTab) && "center",
                }}
              >
                {isMobile || isTablet || isMobTab || isMobileSmall ? (
                  <img
                    src={Weekend_Drive_Mob}
                    alt="Weekend_Drive_mob"
                    className="pt-4"
                  />
                ) : (
                  <img src={Weekend_Drive} alt="Weekend_Drive" />
                )}
              </div>
              {!(isMobile || isTablet || isMobTab || isMobileSmall) && (
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <img src={Weekend} alt="Weekend_Drive" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewCommunities;
