import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import RenderError from "@components/Error/RenderError";
import { useResetPasswordMutation } from "@redux/services/auth/loginService";
import CheckPassword from "@components/CheckPassword";
import Form from "@components/Forms/Formik/Form";
import "./ConfirmPassword.scss";
import { toast } from "react-toastify";

const ConfirmPassword = React.memo(
  ({ userData, checkEmailModal, resetModal, closeModal }) => {
    const [resetPassword] = useResetPasswordMutation();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isDisableBtn, setDisableBtn] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const PasswordValidationSchema = Yup.object().shape({
      password: Yup.string()
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Password must contain at least 1 special character"
        )
        .matches(/[A-Z]/, "Password must contain at least 1 uppercase letter")
        .matches(/[0-9]/, "Password must contain at least 1 number")
        .min(8, "Password must be at least 8+ characters")
        .required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    });
    function closeAllModal() {
      checkEmailModal();
      resetModal();
      closeModal();
    }
    const formik = useFormik({
      initialValues: {
        password: "",
        confirmPassword: "",
      },
      validationSchema: PasswordValidationSchema,
      onSubmit: async (values) => {
        try {
          setDisableBtn(true);
          const passwordBody = {
            password: values?.password,
            userId: userData?.userId,
          };
          const response = await resetPassword(passwordBody);
          if (response?.data?.code === 200) {
            toast.success("Password Reset Successfully", "success");
            closeAllModal()
          }
        } catch (error) {
          setDisableBtn(false);
          setErrorMsg(error?.data?.message || error?.message);
        } finally {
          setDisableBtn(false);
        }
      },
    });

    useEffect(() => {
      formik.validateForm();
    }, []);

    return (
      <div className="login-wrapper">
        <div className="wrapper-parent">
          <div className="wrapper">
            <p className="reset-sec">Create new password.</p>
            <p>
              Your new password must be different from previously used
              passwords.
            </p>
            <Form
              onSubmit={formik.handleSubmit}
              formik={formik}
              submitText="Reset Password"
              className={"login-btn"}
              disableSubmit={!formik.isValid}
              isDisableBtn={isDisableBtn}
            >
              <div className="form-group my-4  mb-2">
                <div className="form-group position mb-3">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    className="form-control"
                    value={formik.values.password}
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.values.password.length > 0 && (
                    <div
                      className="eye-icon"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <i
                        className={`fas ${
                          showPassword ? "fa-eye" : "fa-eye-slash"
                        }`}
                      />
                    </div>
                  )}
                </div>
                <RenderError
                  message={formik.touched.password && formik.errors.password}
                />
                <div className="form-group position  mb-4">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    className="form-control"
                    value={formik.values.confirmPassword}
                    name="confirmPassword"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.values.confirmPassword.length > 0 && (
                    <div
                      className="eye-icon"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      <i
                        className={`fas ${
                          showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                        }`}
                      />
                    </div>
                  )}
                </div>
                <RenderError
                  message={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                />
                {errorMsg !== "" && <RenderError message={errorMsg} />}
                <CheckPassword password={formik.values.password} />
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
);

export default ConfirmPassword;
