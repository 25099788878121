import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LazyImage = ({ alt, src }) => (
  <LazyLoadImage
    alt={alt}
    effect="blur"
    // wrapperProps={{
    //   // If you need to, you can tweak the effect transition using the wrapper style.
    //   style: { transitionDelay: "1s" },
    // }}
    style={{
      objectFit: "cover",
    }}
    src={src}
  />
);
export default LazyImage;
