import React, { useState, useCallback } from "react";
import "./Reset.scss";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import RenderError from "@components/Error/RenderError";
import CheckEmailModal from "@components/Modal/CheckEmailModal";
import { useResetEmailMutation } from "@redux/services/auth/loginService";

const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Wrong email format")
    .required("Provide a valid email."),
});

const Reset = ({ closeModal }) => {
  const [checkEmailModal, setCheckEmailModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [resetEmail] = useResetEmailMutation();

  const handleSubmit = useCallback(
    async (values, { setSubmitting, setFieldError }) => {
      try {
        const emailForReset = {
          email: values.email,
        };
        const resetResponse = await resetEmail(emailForReset).unwrap();
        if (resetResponse.code === 200) {
          setCheckEmailModal(true);
          setUserData({
            userId: resetResponse.data.userId,
            email: values.email,
          });
        }
      } catch (error) {
        setFieldError("email", error.data.message);
      } finally {
        setSubmitting(false);
      }
    },
    [resetEmail]
  );

  return (
    <div style={{ minHeight: "700px" }}>
      <div
        className="login-wrapper"
        style={{ minHeight: "auto", padding: "50px 0" }}
      >
        <div className="wrapper-parent">
          <div className="wrapper">
            <p className="reset-sec">Reset password.</p>
            <p>
              Enter the email in which you would like the verification code to
              be sent to.
            </p>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={SignInSchema}
              onSubmit={handleSubmit}
            >
              {({
                isSubmitting,
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="form-group my-4 mb-4">
                    <Field
                      type="text"
                      placeholder="Provide Email"
                      className="form-control"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <RenderError message={touched.email && errors.email} />
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="login-btn"
                  >
                    {isSubmitting ? "Sending..." : "Send Reset Link"}
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <CheckEmailModal
        checkEmail={checkEmailModal}
        closeModal={() => setCheckEmailModal(false)}
        userData={userData}
        resetModal={closeModal}
      />
    </div>
  );
};

export default Reset;
