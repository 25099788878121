import React, { useState } from "react";
import { Link } from "react-router-dom";
import { isValidUrl } from "@utils/validUrl";
import DefaultUserImg from "@assets/defaultUser.png";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import { ReactComponent as Comments } from "@assets/comments.svg";
import { ReactComponent as ThumbsUp } from "@assets/carDetailsImgs/thumb-up.svg";
import { ReactComponent as ThreeDots } from "@assets/three-dots.svg";

import { dateTimeFromNow } from "@utils/DateFormatter";
import { useSelector } from "react-redux";
import ReadMore from "../../../../ReadMore";
import { useReactionOnCommentsMutation } from "@redux/services/commentsService/commentService";

const ShowReplies = ({ item, isTablet, rep }) => {
  // {GLOBALLY GET USER LOGIN IN DATA}
  const user = useSelector((state) => state?.auth?.loginUserData);
  //
  // {USESTATES}
  const [isLikedReply, setIsLikedReply] = useState(rep?.isReacted || false); // Initial state for like status
  const [reactionCount, setReactionCount] = useState(rep?.reactionCount || 0); // Initial state for reaction count
  //
  // {API CALL}
  const [reactionOnComments] = useReactionOnCommentsMutation();
      console.log("response-->", rep);

  //
  // {FUNCTION CALL}
  const reactionComments = async () => {
    // Optimistically update the state
    const optimisticIsLiked = !isLikedReply;
    const optimisticReactionCount = isLikedReply
      ? reactionCount - 1
      : reactionCount + 1;
    setIsLikedReply(optimisticIsLiked);
    setReactionCount(optimisticReactionCount);

    const body = {
      reactionType: !isLikedReply ? 1 : 0, // 0 for unlike, 1 for like
      modelId: rep?.modelId,
      model: rep?.model,
      commentId: rep?.id,
    };
    try {
      const response = await reactionOnComments(body).unwrap();
      if (response?.code !== 200) {
        setIsLikedReply(!optimisticIsLiked);
        setReactionCount(reactionCount);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  //
  return (
    <>
      <div className="reply">
        <>
          {item?._id === rep?.parentCommentId && (
            <>
              <div style={{ position: "relative" }}>
                <div
                  className="wrapper-replies-header"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="avtar-in mt-4">
                    <Link
                      to={`/profile/${rep?.userId}`}
                      className="profile"
                      // style={{ flex: "0 0 60px" }}
                    >
                      <img
                        src={
                          isValidUrl(rep?.user?.profileImage)
                            ? rep?.user?.profileImage
                            : DefaultUserImg
                        }
                        alt={rep?.user?.username}
                        className="main-img"
                      />
                    </Link>

                    <div style={{ marginLeft: "10px" }}>
                      <div className="name">
                        <Link
                          to={`/profile/${rep?.userId}`}
                          className="profile-link"
                        >
                          {rep?.user?.username.length > 15
                            ? sliceStringAtWord(
                                rep?.user?.username,
                                isTablet ? 5 : 15
                              )
                            : rep?.user?.username}
                        </Link>
                      </div>
                    </div>
                    <div
                      className="dot"
                      style={{
                        backgroundColor: "#8E8E93",
                        width: "8px",
                        height: "8px",
                        borderRadius: "10px",
                      }}
                    ></div>
                    <div className="d-flex align-items-center">
                      {rep?.createdAt && dateTimeFromNow(rep?.createdAt)}
                    </div>
                  </div>
                  {user?._id === rep?.user?._id && (
                    <Link
                      role="button"
                      // onClick={() => toggleDropdownReplies(index)}
                    >
                      <ThreeDots />
                    </Link>
                  )}
                </div>

                <div className="show-more-title-nested-replies">
                  <ReadMore sliceText={250}>
                    {(rep?.commentText && rep?.commentText) || ""}
                  </ReadMore>

                  <div className="actions-btns">
                    <div className="likeUnLike">
                      <button
                        style={{
                          border: "none",
                          background: "none",
                          cursor: "pointer",
                        }}
                        onClick={reactionComments}

                        // disabled={isDisableLike}
                      >
                        <ThumbsUp
                          fill={isLikedReply ? "white" : "transparent"}
                        />
                      </button>
                      {reactionCount > 0 && <span>{reactionCount}</span>}
                    </div>
                    <div
                      className="likeUnLike"
                      onClick={() => {
                        // setModelId(rep?._id);
                        // setParentCommentId(rep?.parentCommentId);
                        // handleClickScroll();
                      }}
                    >
                      <Comments />
                    </div>
                  </div>
                </div>

                {/* {openReplies === index && (
                          <DeleteComment
                            item={rep}
                            setRateId={setRateId}
                            rateId={rateId}
                            setCommentDeleteId={setFilterReplieId}
                            setComments={setReplies}
                            filterCommentsDelete={filterReplies}
                            commentsCountUpdate={commentsCountUpdate}
                            setCommentsCountUpdate={setCommentsCountUpdate}
                            setStoreReplyCount={setStoreReplyCount}
                            storeReplyCount={storeReplyCount}
                            setUpdateComments={setUpdateComments}
                            getAllCommentRepliesForStep={
                              getAllCommentRepliesForStep
                            }
                            storeReplieId={storeReplieId}
                            setOpenEditDelete={setOpenReplies}
                          />
                        )} */}
              </div>
            </>
          )}
        </>
      </div>
    </>
  );
};

export default ShowReplies;
