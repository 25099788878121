import React from 'react'
import PartImg from "@assets/car-part.png";
import "@containers/searchcomponent/SearchPage.scss";

const PostPage = ({ statePost }) => {
  console.log('statePost', statePost)
  return (
    <div>
      <div className="view-all-heading">
        <h2>Posts </h2>
        <a href="#">
          See More
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.25 11.25L10 7.5L6.25 3.75"
              stroke="#387AFF"
              stroke-linecap="round"
            />
          </svg>
        </a>
      </div>
      <div className="Trendingparts">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="trending-part-block">
              <a href="/vehicle-profile">
                <div className="vehicle-avtar">
                  <div className="img-holders">
                    <img src={PartImg} alt="" className="" />
                    <img src={PartImg} alt="" className="" />
                  </div>
                  <div className="name-info">
                    <div className="name">Vehicle name</div>
                    <p>Owner</p>
                  </div>
                </div>

                <div className="post-title-trending">Post title</div>
                <img src={PartImg} alt="" className="main-img" />
              </a>
              <div className="post-short-info">
                Lorem ipsum dolor sit amet, consectetur elit ac, nunc taciti
                odio vulputate libero et velit aliquet.{" "}
              </div>
              <div className="actions-btns trending-post-btns">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.48149 7.43827C8.17078 7.80075 8 8.26243 8 8.73985V17C8 18.1046 8.89543 19 10 19H17.3308C18.2974 19 19.1257 18.3088 19.2986 17.3578L20.5713 10.3578C20.7945 9.13009 19.8514 8 18.6036 8H14L15.6279 3.11634C15.8414 2.47579 15.5514 1.77571 14.9475 1.47375V1.47375C14.3906 1.19532 13.7158 1.33161 13.3106 1.80431L8.48149 7.43827Z"
                      stroke="white"
                    />
                    <path d="M4 18V9" stroke="white" stroke-linecap="round" />
                  </svg>
                  <span>4</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 17V17.5C21.2761 17.5 21.5 17.2761 21.5 17H21ZM21 4H21.5C21.5 3.72386 21.2761 3.5 21 3.5V4ZM13 17V16.5C12.9013 16.5 12.8048 16.5292 12.7226 16.584L13 17ZM7 21H6.5C6.5 21.1844 6.60149 21.3538 6.76407 21.4408C6.92665 21.5278 7.12392 21.5183 7.27735 21.416L7 21ZM7 17H7.5C7.5 16.7239 7.27614 16.5 7 16.5V17ZM3 17H2.5C2.5 17.2761 2.72386 17.5 3 17.5V17ZM3 4V3.5C2.72386 3.5 2.5 3.72386 2.5 4H3ZM21.5 17V4H20.5V17H21.5ZM13 17.5H21V16.5H13V17.5ZM7.27735 21.416L13.2774 17.416L12.7226 16.584L6.72265 20.584L7.27735 21.416ZM6.5 17V21H7.5V17H6.5ZM3 17.5H7V16.5H3V17.5ZM2.5 4V17H3.5V4H2.5ZM21 3.5H3V4.5H21V3.5Z"
                      fill="white"
                    />
                  </svg>
                  <span>11</span>
                </div>

                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17 6.65882V19.5858L12.5364 16.7487C12.2091 16.5407 11.7909 16.5407 11.4636 16.7487L7 19.5858L7 6.65882C7 5.19039 8.11929 4 9.5 4L14.5 4C15.8807 4 17 5.19039 17 6.65882Z"
                      stroke="white"
                      stroke-linecap="round"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path d="M12 15V3" stroke="white" stroke-linecap="round" />
                    <path
                      d="M9 5L12 2L15 5"
                      stroke="white"
                      stroke-linecap="round"
                    />
                    <rect
                      x="6"
                      y="9"
                      width="12"
                      height="12"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>

              <div className="comments-info">
                <span className="green-text">Component Post</span>{" "}
                <span className="time">1hr ago</span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="trending-part-block">
              <a href="/vehicle-profile">
                <div className="vehicle-avtar">
                  <div className="img-holders">
                    <img src={PartImg} alt="" className="" />
                    <img src={PartImg} alt="" className="" />
                  </div>
                  <div className="name-info">
                    <div className="name">Vehicle name</div>
                    <p>Owner</p>
                  </div>
                </div>
              </a>
              <div className="post-title-trending">Post title</div>
              <img src={PartImg} alt="" className="main-img" />
              <div className="post-short-info">
                Lorem ipsum dolor sit amet, consectetur elit ac, nunc taciti
                odio vulputate libero et velit aliquet.{" "}
              </div>
              <div className="actions-btns trending-post-btns">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.48149 7.43827C8.17078 7.80075 8 8.26243 8 8.73985V17C8 18.1046 8.89543 19 10 19H17.3308C18.2974 19 19.1257 18.3088 19.2986 17.3578L20.5713 10.3578C20.7945 9.13009 19.8514 8 18.6036 8H14L15.6279 3.11634C15.8414 2.47579 15.5514 1.77571 14.9475 1.47375V1.47375C14.3906 1.19532 13.7158 1.33161 13.3106 1.80431L8.48149 7.43827Z"
                      stroke="white"
                    />
                    <path d="M4 18V9" stroke="white" stroke-linecap="round" />
                  </svg>
                  <span>4</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 17V17.5C21.2761 17.5 21.5 17.2761 21.5 17H21ZM21 4H21.5C21.5 3.72386 21.2761 3.5 21 3.5V4ZM13 17V16.5C12.9013 16.5 12.8048 16.5292 12.7226 16.584L13 17ZM7 21H6.5C6.5 21.1844 6.60149 21.3538 6.76407 21.4408C6.92665 21.5278 7.12392 21.5183 7.27735 21.416L7 21ZM7 17H7.5C7.5 16.7239 7.27614 16.5 7 16.5V17ZM3 17H2.5C2.5 17.2761 2.72386 17.5 3 17.5V17ZM3 4V3.5C2.72386 3.5 2.5 3.72386 2.5 4H3ZM21.5 17V4H20.5V17H21.5ZM13 17.5H21V16.5H13V17.5ZM7.27735 21.416L13.2774 17.416L12.7226 16.584L6.72265 20.584L7.27735 21.416ZM6.5 17V21H7.5V17H6.5ZM3 17.5H7V16.5H3V17.5ZM2.5 4V17H3.5V4H2.5ZM21 3.5H3V4.5H21V3.5Z"
                      fill="white"
                    />
                  </svg>
                  <span>11</span>
                </div>

                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17 6.65882V19.5858L12.5364 16.7487C12.2091 16.5407 11.7909 16.5407 11.4636 16.7487L7 19.5858L7 6.65882C7 5.19039 8.11929 4 9.5 4L14.5 4C15.8807 4 17 5.19039 17 6.65882Z"
                      stroke="white"
                      stroke-linecap="round"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path d="M12 15V3" stroke="white" stroke-linecap="round" />
                    <path
                      d="M9 5L12 2L15 5"
                      stroke="white"
                      stroke-linecap="round"
                    />
                    <rect
                      x="6"
                      y="9"
                      width="12"
                      height="12"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>

              <div className="comments-info">
                <span className="blue-text">Component Post</span>{" "}
                <span className="time">1hr ago</span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="trending-part-block">
              <a href="/vehicle-profile">
                <div className="vehicle-avtar">
                  <div className="img-holders">
                    <img src={PartImg} alt="" className="" />
                    <img src={PartImg} alt="" className="" />
                  </div>
                  <div className="name-info">
                    <div className="name">Vehicle name</div>
                    <p>Owner</p>
                  </div>
                </div>
              </a>
              <div className="post-title-trending">Post title</div>
              <img src={PartImg} alt="" className="main-img" />
              <div className="post-short-info">
                Lorem ipsum dolor sit amet, consectetur elit ac, nunc taciti
                odio vulputate libero et velit aliquet.{" "}
              </div>
              <div className="actions-btns trending-post-btns">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.48149 7.43827C8.17078 7.80075 8 8.26243 8 8.73985V17C8 18.1046 8.89543 19 10 19H17.3308C18.2974 19 19.1257 18.3088 19.2986 17.3578L20.5713 10.3578C20.7945 9.13009 19.8514 8 18.6036 8H14L15.6279 3.11634C15.8414 2.47579 15.5514 1.77571 14.9475 1.47375V1.47375C14.3906 1.19532 13.7158 1.33161 13.3106 1.80431L8.48149 7.43827Z"
                      stroke="white"
                    />
                    <path d="M4 18V9" stroke="white" stroke-linecap="round" />
                  </svg>
                  <span>4</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M21 17V17.5C21.2761 17.5 21.5 17.2761 21.5 17H21ZM21 4H21.5C21.5 3.72386 21.2761 3.5 21 3.5V4ZM13 17V16.5C12.9013 16.5 12.8048 16.5292 12.7226 16.584L13 17ZM7 21H6.5C6.5 21.1844 6.60149 21.3538 6.76407 21.4408C6.92665 21.5278 7.12392 21.5183 7.27735 21.416L7 21ZM7 17H7.5C7.5 16.7239 7.27614 16.5 7 16.5V17ZM3 17H2.5C2.5 17.2761 2.72386 17.5 3 17.5V17ZM3 4V3.5C2.72386 3.5 2.5 3.72386 2.5 4H3ZM21.5 17V4H20.5V17H21.5ZM13 17.5H21V16.5H13V17.5ZM7.27735 21.416L13.2774 17.416L12.7226 16.584L6.72265 20.584L7.27735 21.416ZM6.5 17V21H7.5V17H6.5ZM3 17.5H7V16.5H3V17.5ZM2.5 4V17H3.5V4H2.5ZM21 3.5H3V4.5H21V3.5Z"
                      fill="white"
                    />
                  </svg>
                  <span>11</span>
                </div>

                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17 6.65882V19.5858L12.5364 16.7487C12.2091 16.5407 11.7909 16.5407 11.4636 16.7487L7 19.5858L7 6.65882C7 5.19039 8.11929 4 9.5 4L14.5 4C15.8807 4 17 5.19039 17 6.65882Z"
                      stroke="white"
                      stroke-linecap="round"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path d="M12 15V3" stroke="white" stroke-linecap="round" />
                    <path
                      d="M9 5L12 2L15 5"
                      stroke="white"
                      stroke-linecap="round"
                    />
                    <rect
                      x="6"
                      y="9"
                      width="12"
                      height="12"
                      stroke="white"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>

              <div className="comments-info">
                <span className="green-text">Component Post</span>{" "}
                <span className="time">1hr ago</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostPage