import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "@redux/slices/authSlice/loginSlice";
import { loginApi } from "@redux/services/auth/loginService";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { signUpApi } from "@redux/services/auth/signUpService";
import signUpSlice from "@redux/slices/authSlice/signUpSlice";
import { feedsApi } from "@redux/services/mainFeed/feedService";
import { commentsApi } from "@redux/services/commentsService/commentService";
import { reactionApi } from "@redux/services/reactionService/reactionService";
import { allCrewApi } from "@redux/services/crewService/allCrewService";
import { UserProfileApi } from "@redux/services/userProfile/UserProfileService";
import { componentPageApi } from "@redux/services/userProfile/BuildComponentService";
import { publicService } from "@redux/services/public/publicService";
import publicSlice from "@redux/slices/authSlice/publicSlice";
import { storyApi } from "@redux/services/Stories/storyServices";


const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "signUp"], // Specify the slices you want to persist
};

const reducers = {
  [authSlice.reducerPath]: authSlice.reducer,
  [signUpSlice.reducerPath]: signUpSlice.reducer,
  [signUpApi.reducerPath]: signUpApi.reducer,
  [feedsApi.reducerPath]: feedsApi.reducer,
  [commentsApi.reducerPath]: commentsApi.reducer,
  [reactionApi.reducerPath]: reactionApi.reducer,
  [allCrewApi.reducerPath]: allCrewApi.reducer,
  [storyApi.reducerPath]: storyApi.reducer,
  [UserProfileApi.reducerPath]: UserProfileApi.reducer,
  [componentPageApi.reducerPath]: componentPageApi.reducer,
  [publicService.reducerPath]: publicService.reducer,
  [publicSlice.reducerPath]: publicSlice.reducer,

  // [authApi.reducerPath]: authApi.reducer, // Include the API reducer
};

const rootReducer = combineReducers(reducers);
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      loginApi.middleware,
      signUpApi.middleware,
      feedsApi.middleware,
      storyApi.middleware,
      commentsApi.middleware,
      reactionApi.middleware,
      allCrewApi.middleware,
      UserProfileApi.middleware,
      componentPageApi.middleware,
      publicService.middleware
    ),
});

export const persistor = persistStore(store);

export default store;
