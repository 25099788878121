import React from "react";
import WrapperModal from "./WrapperModal";
import SliderShow from "@containers/BuildProfile/components/SliderShow";

const SliderModalImg = React.memo(({ open, closeModal, buildData }) => {
  return (
    <div id="modal">
      <WrapperModal
        open={open}
        closeModal={closeModal}
        style={{
          top: "50%",
          transform: "translateY(-50%)",
          left: 0,
          right: 0,
          margin: "0 auto",
          backgroundColor: "#121313",
        }}
      >
        <SliderShow media={buildData?.media} />
      </WrapperModal>
    </div>
  );
});

export default SliderModalImg;
