import React from "react";
import ContentLoader from "react-content-loader";

const USerProfileSkeleton = () => {
  return (
    <ContentLoader
      height="200"
      width="600"
      viewBox="0 0 600 200"
      backgroundColor="#f3e7e7"
      foregroundColor="#18191A"
      speed={2}
    >
      <rect x="190" y="50" rx="5" ry="5" width="140" height="30" />

      <rect x="20" y="10" rx="3" ry="3" width="140" height="140" />

      <rect x="190" y="120" rx="3" ry="3" width="70" height="20" />
      <rect x="290" y="120" rx="3" ry="3" width="70" height="20" />
      <rect x="390" y="120" rx="3" ry="3" width="70" height="20" />
      <rect x="490" y="120" rx="3" ry="3" width="70" height="20" />
    </ContentLoader>
  );
};

export default USerProfileSkeleton;
