import React, { Component, useState } from "react";
import "@components/layouts/DropDowns.scss";

export default function ExploreDropDowns() {
  const [searchVal, setSearchVal] = useState("");
  return (
    <div>
      <div className="banner-search">
        <div className="search-form">
          <div className="search-fld">
            <input
              type="search"
              className="form-control"
              onChange={(e) => setSearchVal(e.target.value)}
              placeholder="Search for anything"
            />

            {/* <button>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <path
                  d="M12.0094 12.5102L16.666 17.1667"
                  stroke="#F5F5F5"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.33398 13.8333C11.0954 13.8333 13.334 11.5947 13.334 8.83325C13.334 6.07183 11.0954 3.83325 8.33398 3.83325C5.57256 3.83325 3.33398 6.07183 3.33398 8.83325C3.33398 11.5947 5.57256 13.8333 8.33398 13.8333Z"
                  stroke="#F5F5F5"
                  stroke-width="1.5"
                />
              </svg>
            </button> */}
          </div>
          {searchVal && (
            <div className="search-dropdown">
              <div className="result-loop">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M12.0094 12.5102L16.666 17.1667"
                    stroke="#F5F5F5"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.33398 13.8333C11.0954 13.8333 13.334 11.5947 13.334 8.83325C13.334 6.07183 11.0954 3.83325 8.33398 3.83325C5.57256 3.83325 3.33398 6.07183 3.33398 8.83325C3.33398 11.5947 5.57256 13.8333 8.33398 13.8333Z"
                    stroke="#F5F5F5"
                    stroke-width="1.5"
                  />
                </svg>
                <p style={{ margin: "unset" }}>Local meetups</p>
                <svg
                  className="arrow-svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="back-right">
                    <path
                      id="Path 3"
                      d="M12.6673 9.16675V3.83341H7.33398"
                      stroke="white"
                      stroke-linecap="round"
                    />
                    <path
                      id="Path 31"
                      d="M12.6673 3.83325L3.33398 13.1666"
                      stroke="white"
                      stroke-linecap="round"
                    />
                  </g>
                </svg>
              </div>
              <div className="result-loop">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M12.0094 12.5102L16.666 17.1667"
                    stroke="#F5F5F5"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.33398 13.8333C11.0954 13.8333 13.334 11.5947 13.334 8.83325C13.334 6.07183 11.0954 3.83325 8.33398 3.83325C5.57256 3.83325 3.33398 6.07183 3.33398 8.83325C3.33398 11.5947 5.57256 13.8333 8.33398 13.8333Z"
                    stroke="#F5F5F5"
                    stroke-width="1.5"
                  />
                </svg>
                <p style={{ margin: "unset" }}>
                  Local meetups <span className="tag">Builds</span>
                </p>
                <svg
                  className="arrow-svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="back-right">
                    <path
                      id="Path 3"
                      d="M12.6673 9.16675V3.83341H7.33398"
                      stroke="white"
                      stroke-linecap="round"
                    />
                    <path
                      id="Path 31"
                      d="M12.6673 3.83325L3.33398 13.1666"
                      stroke="white"
                      stroke-linecap="round"
                    />
                  </g>
                </svg>
              </div>
              {/*<div className="spliter"></div>
              <div className="result-loop">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M12.0094 12.5102L16.666 17.1667"
                    stroke="#F5F5F5"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.33398 13.8333C11.0954 13.8333 13.334 11.5947 13.334 8.83325C13.334 6.07183 11.0954 3.83325 8.33398 3.83325C5.57256 3.83325 3.33398 6.07183 3.33398 8.83325C3.33398 11.5947 5.57256 13.8333 8.33398 13.8333Z"
                    stroke="#F5F5F5"
                    stroke-width="1.5"
                  />
                </svg>
                <p>Local meetups</p>
                <svg
                  className="arrow-svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="back-right">
                    <path
                      id="Path 3"
                      d="M12.6673 9.16675V3.83341H7.33398"
                      stroke="white"
                      stroke-linecap="round"
                    />
                    <path
                      id="Path 31"
                      d="M12.6673 3.83325L3.33398 13.1666"
                      stroke="white"
                      stroke-linecap="round"
                    />
                  </g>
                </svg>
              </div> */}
            </div>
          )}
        </div>
        <div className="search-filter">
          <select
            className="default-select"
            style={{
              border: "1px solid #8E8E93",
              color: "#8E8E93",
              boxShadow: "none",
              background: "none",
              height: "42px",
            }}
          >
            <option>All categories</option>
            <option>All categories</option>
          </select>
        </div>
        <div>
          <button
            className="default-btn"
            style={{ borderRadius: "5px", padding: "8.5px 30px", width: "125px" }}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
}
