import React, { useState } from "react";
import "./banner.scss";
import CallToAction from "./CallToAction";
import Header from "@components/layouts/Header";
import Footer from "@components/layouts/Footer";
import CrewsBuilds from "./CrewsBuilds";
import ExploreParts from "./ExploreParts";
import TrendingParts from "./TrendingParts";
import { useGetPublicComponentQuery } from "@redux/services/public/publicService";

export default function HomeBanner() {
  // const [open, setOpen] = useState(false);
  const [navigateVal, setNavigateVal] = useState("");

  /**
   * API CALLS
   */
  const {
    data: { data: { docs: componentsData } = {} } = [],
    isLoading: loading,
  } = useGetPublicComponentQuery();

  

  return (
    <div>
      <Header />
      <div className="home-banner" style={{ height: "50svh" }}>
        {/* <div className="container">
          <h1>Discover Your Community, with Carvonix</h1>
          <form>
            <input
              type="search"
              placeholder="Search for"
              className="search"
            ></input>
            <select className="selectOption">
              <option>All categories</option>
              <option>All categories</option>
              <option>All categories</option>
            </select>
            <button type="submit">
              <i className="fa-solid fa-magnifying-glass"></i>
            </button>
          </form>
          <p>
            Find inspiration, search for parts, and create your dream build with
            the world’s first app built by auto enthusiasts, for auto
            enthusiasts.
          </p>
          <div className="quick-search">
            <h4>Quick search</h4>
            <span>Trending</span>
            <span>Builds</span>
            <span>Reviews</span>
            <span>How to</span>
          </div>
        </div> */}
      </div>
      <CallToAction />
      {/* <ExploreParts /> */}
      {/* <div className="container">
      <div className="specifications">
            <span>Performance</span>
            <span>Suspension</span>
            <span>Drive Train</span>
            <span>Wheels and Tires</span>
            <span>Electrical</span>
            <span>Lighting</span>
            <span>Interior</span>
            <span>Exterior</span>
            <span>Maintainance</span>
          </div>
          </div> */}
      {/* <TrendingParts
        componentsData={componentsData}
        // setOpen={setOpen}
        setNavigateVal={setNavigateVal}
        navigateVal={navigateVal}
        isLoading={loading}
      /> */}
      <CrewsBuilds
        // setOpen={setOpen}
      />
      {/* {open && (
        <SignInModal
          setModal={setOpen}
          closeModal={open}
          navigateVal={navigateVal}
        />
      )} */}
      <Footer />
    </div>
  );
}
