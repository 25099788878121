import React from "react";
import { Link } from "react-router-dom";
const DropMenu = ({ post, dropDownTitle }) => {
  const handleList = (title) => {
    console.log("title987", title);
    if (title === "Unfollow" || title === "Follow") {
    }
  };
  const listDropDown = [
    // {
    //   //  userData?.isFollowed ? "Unfollow" :
    //   title: "Follow",
    //   Image: FollowUnFllow,
    // },
    {
      title: "Profile",
      to: "/setting",
    },
    post.parentType === 2 && {
      title: "Build profile",
      to: "/setting",
    },

    {
      title: "Save",
      to: "/setting",
    },
    {
      title: "Share",
      to: "/setting",
    },
    {
      title: "Link",
      to: "/setting",
    },
    {
      title: "Report",
      to: "/setting",
    },
  ];
  return (
    <div>
      <ul className="dropdown-menu-feed">
        {listDropDown?.map(({ title }) => {
          return (
            title && (
              <>
                <li key={title} style={{ paddingBottom: "10px" }}>
                  <Link
                    to={`${
                      title === "Profile"
                        ? `/profile/${post?.userId ?? post?.user?._id}`
                        : title === "Build profile" &&
                          `/${post?.user?.username}/garage/${post?.build?.make}/${post?.build?.model}/${post?.build?.year?.value}/${post?.build?._id}`
                    }`}
                    className="dropdown-item"
                    onClick={() => handleList(title)}
                    style={{
                      color: dropDownTitle?.includes(title) ? "#8E8E93" : "",
                      cursor: dropDownTitle?.includes(title)
                        ? "not-allowed"
                        : "",
                    }}
                  >
                    {title && (
                      <img
                        src={require("assets/dropDownImages/" + title + ".svg")}
                        style={{
                          width: "25px",
                          height: "25px",
                          objectFit: "cover",
                          marginRight: "20px",
                          marginLeft: "0px",
                        }}
                        className="avtar-img"
                        alt="img78"
                      />
                    )}
                    {title}
                  </Link>
                </li>
              </>
            )
          );
        })}
      </ul>
    </div>
  );
};

export default DropMenu;
