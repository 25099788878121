import React, { useEffect, useState } from "react";
import "./bannerwithsearch.scss";
import { useLocation } from "react-router";
import ExploreDropDowns from "./ExploreDropDown";

export default function BannerWithSearch() {
  const location = useLocation();
  console.log('location22', location)
  //   const [open, setOpen] = useState(false);
  //   const [componentsData, setComponentData] = useState([]);
  //   const [navigateVal, setNavigateVal] = useState("");
  //   console.log("navigateVal", navigateVal);
  //   useEffect(() => {
  //     const getPublicComponents = async () => {
  //       try {
  //         const response = await getPublicComponent();
  //         setComponentData(response?.data?.data?.docs || []);
  //       } catch (error) {
  //         showNotification(error?.response?.data?.message, "error");
  //       }
  //     };
  //     getPublicComponents();
  //   }, []);

  return (
    <div>
      {/* <Header /> */}
      <div className="home-banner">
        <div className="container">
          <h5>Redefining the car community,</h5>
          <h1>one build at a time</h1>
          <ExploreDropDowns/>
          {/* <form>
            <input
              type="search"
              placeholder="Search for"
              className="search"
            ></input>
            <select className="selectOption">
              <option>All categories</option>
              <option>All categories</option>
              <option>All categories</option>
            </select>
            <button type="submit">Search</button>
          </form> */}
          <div className="quick-search" style={{maxWidth:"538px"}}>
            <h4>Quick search</h4>
            <span>Trending</span>
            <span>Builds</span>
            <span>Reviews</span>
            <span>How to</span>
          </div>
        </div>
      </div>
      {/* <CallToAction />
      <ExploreParts />
      <TrendingParts
        componentsData={componentsData}
        setOpen={setOpen}
        setNavigateVal={setNavigateVal}
      />
      <CrewsBuilds setOpen={setOpen} />
      {open && (
        <SignInModal
          open={open}
          closeModal={() => setOpen(false)}
          navigateVal={navigateVal}
        />
      )}
      <Footer /> */}
    </div>
  );
}
