import LandingFooter from "@components/layouts/LandingFooter";
import LandingHeader from "@components/layouts/LandingHeader";
import React, { useEffect, useState } from "react";
import "./Landing.scss";
import HeroSection from "./HeroSection";
import Communities from "./Communities";
import GarageUpgrade from "./GarageUpgrade";
import SeamlessEvent from "./SeamlessEvent";
import NewCommunities from "./NewCommunities";
import MeetUpEvents from "./MeetUpEvents";
import {
  backgroundLogo,
  CrewPost,
  VehiclePost,
  HomePost,
  ProfilePost,
} from "@utils/defaultImages";
import useMediaQueryHook from "@utils/hooks/useMediaQueryHook";
import LottieCrew from "@components/Lottie/web-crew.json";

const multiplePost = [
  {
    title: "Share, build, socialize",
    description:
      "The home feed is where all of your content will be posted and displayed from your followers. Tailored directly to the users' own personal preferences.",
    LinkImg: HomePost,
  },
  {
    title: "Find New Communities",
    description:
      "Connect with communities around specific interests, models, or regions.",
    LinkImg: LottieCrew,
  },
  {
    title: "Your Cars, Your Way",
    description:
      "Craft your automotive identity, showcase your ride's unique personality.",
    LinkImg: VehiclePost,
  },
  {
    title: "Personalize Your Identity",
    description:
      "Your profile page is your central hub for all your vehicles, crews, posts, and more.",
    LinkImg: ProfilePost,
  },
];
const Landing = () => {
  const [scrollDirection, setScrollDirection] = useState(false);

  const [activeLink, setActiveLink] = useState(0);
  const { isMobile } = useMediaQueryHook();

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById("hero-section");
      const rect = heroSection.getBoundingClientRect();

      if (rect.bottom <= 0) {
        setScrollDirection(true);
      } else {
        setScrollDirection(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="landing-container">
        {scrollDirection && <LandingHeader />}
        <div
          className="content"
          style={{ backgroundImage: `url(${backgroundLogo})` }}
          id="hero-section"
        >
          <HeroSection />
        </div>
        <div className="slider" id="communities">
          <Communities
            multiplePost={multiplePost}
            activeLink={activeLink}
            setActiveLink={setActiveLink}
          />
        </div>
        <div className="garage-upgrade" id="next-section">
          <GarageUpgrade />
        </div>
        <div className="seamless">
          <SeamlessEvent />
        </div>
        <div className="new-communities">
          <NewCommunities />
        </div>
        <div className="meet-up">
          <MeetUpEvents />
        </div>
        <LandingFooter />
      </div>
    </>
  );
};

export default Landing;
