import React, { useState, useEffect, useRef } from "react";
import BannerImg from "@assets/userProfileImgs/default.png";
import gps from "@assets/gps.png";
import Posts from "../BuildProfile/components/Posts";
import "./UserProfile.scss";
import ReadMore from "@components/ReadMore";
import USerProfileSkeleton from "@components/Skeleton/USerProfileSkeleton";
import { isValidUrl } from "@utils/validUrl";
import { useGetUserProfileQuery } from "@redux/services/userProfile/UserProfileService";
import UserBuild from "./subComponents/UserBuild";
import UserCrews from "./subComponents/UserCrews";
import FollowersAndFollowingModal from "@components/Modal/FollowersAndFollowingModal";
import { sliceStringAtWord } from "@utils/sliceStringWord";
import ProfileHeader from "./subComponents/ProfileHeader";
import { useMediaQuery } from "react-responsive";
import { ThreeDots } from "@utils/defaultImages";
import ThreeDotsModal from "@components/Modal/ThreeDotsModal";
import MembershipDashboardModal from "@components/Modal/MembershipDashboard/membershipDashboardModal";

function UserProfile() {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const { data: { data: { user: userInfo } = {} } = [], isLoading } =
    useGetUserProfileQuery();

  const [isOpen, setIsOpen] = useState(false);
  const [dotsOpen, setDotsOpen] = useState(false);
  const [membershipOpen, setMembershipOpen] = useState(false);
  // Ref for the three dots modal
  const dotsModalRef = useRef(null);
  const closeThreeDotsModal = (key) => {
    setDotsOpen(false);
    if (key === "membership") {
      setMembershipOpen(true);
    }
  };
  // Handle clicks outside of the three dots modal to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dotsModalRef.current &&
        !dotsModalRef.current.contains(event.target)
      ) {
        setDotsOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickToScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleDotClick = () => {
    setDotsOpen(true);
  };

  return (
    <>
      <div>
        {isLoading ? (
          <div className="user-profile-section">
            <img src={BannerImg} className="banner-img" />
            <div className="container">
              <div className="user-data">
                <USerProfileSkeleton />
              </div>
            </div>
          </div>
        ) : (
          <div className="user-profile-section">
            {isMobile && (
              <ProfileHeader
                firstName={userInfo?.firstName}
                lastName={userInfo?.lastName}
              />
            )}

            <img
              src={
                isValidUrl(userInfo?.profileBanner)
                  ? userInfo?.profileBanner
                  : BannerImg
              }
              className="banner-img"
            />
            <div className="container">
              <div className="user-data">
                <div className="avtar">
                  <img
                    src={
                      isValidUrl(userInfo?.profileImage)
                        ? userInfo?.profileImage
                        : BannerImg
                    }
                    className="avtar-img"
                    alt="user-image"
                  />
                </div>
                    <div className="user-data-dot">
                    <div className="userInfo">
                    <div className="name">
                      {userInfo?.firstName &&
                        (userInfo.firstName.length > 15
                          ? sliceStringAtWord(userInfo?.firstName, 15)
                          : userInfo?.firstName)}
                      &nbsp;
                      {userInfo?.lastName &&
                        (userInfo.lastName.length > 15
                          ? sliceStringAtWord(userInfo?.lastName, 15)
                          : userInfo?.lastName)}
                    </div>
                  <div className="UserName">
                    @
                    {userInfo?.username &&
                      (userInfo?.username?.length > 15
                        ? sliceStringAtWord(userInfo?.username, 15)
                        : userInfo?.firstName)}
                    {userInfo?.location?.name ? (
                      <div className="locationIcon">
                        <img src={gps} />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="UserLocation">
                      <span>{userInfo?.location?.name}</span>
                    </div>
                  </div>

                  <div className="followers">
                    <div className="clm">
                      <div
                        style={{ color: "white" }}
                        onClick={() => {
                          setIsOpen(!isOpen);
                        }}
                      >
                        <div className="number">{userInfo?.followerCount}</div>
                        <p>Followers</p>
                      </div>
                    </div>

                    <div
                      className="clm"
                      onClick={() => handleClickToScroll("posts-scroll")}
                    >
                      <div type="button" className="number">
                        {userInfo?.postCount}
                      </div>
                      <p>Posts</p>
                    </div>
                    <div
                      className="clm"
                      onClick={() => handleClickToScroll("vehicle-scroll")}
                    >
                      <div type="button" className="number">
                        {userInfo?.buildsCount}
                      </div>
                      <p>Vehicles</p>
                    </div>
                  </div>
                  <div className="UserBio">
                    {userInfo?.bio && (
                      <ReadMore sliceText={150} isBreakWord={true}>
                        {userInfo?.bio}
                      </ReadMore>
                    )}
                  </div>
                </div>
                <div className="dott">
                      <div>
                        <ThreeDots onClick={handleDotClick} />
                        <div className="threeDotsModal" ref={dotsModalRef}>
                          {dotsOpen && (
                            <ThreeDotsModal closeModal={closeThreeDotsModal} />
                          )}
                        </div>
                      </div>
                    </div>
                    </div>
              </div>
              <div className="scrollingBar">
                <button
                  className="scrollingButton garage"
                  onClick={() => handleClickToScroll("garage")}
                >
                  Garage
                </button>
                <button
                  className="scrollingButton"
                  onClick={() => handleClickToScroll("crews")}
                >
                  Crews
                </button>
                <button
                  className="scrollingButton active"
                  onClick={() => handleClickToScroll("posts")}
                >
                  Posts
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="wrap-all">
          <div id="garage">
            <UserBuild />
          </div>
          <div id="crews">
            <UserCrews userDataInfo={userInfo} />
          </div>

          <div id="posts">
            <Posts />
          </div>
        </div>
      </div>

      {isOpen && (
        <FollowersAndFollowingModal
          open={isOpen}
          closeModal={() => setIsOpen(false)}
        />
      )}
  
        {membershipOpen && (
          <MembershipDashboardModal
            open={membershipOpen}
            closeModal={() => setMembershipOpen(false)}
          />
        )}
      
    </>
  );
}

export default UserProfile;
