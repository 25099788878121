import React, { useState } from "react";
import MembershipDashboardWrapper from "./memberShipDashboardWrapper";
import "./membershipDashboard.scss";
import infoSvg from "@assets/membershipDashboard/info.svg";
import Bronze from "@assets/membershipDashboard/Bronze.png";
import Silver from "@assets/membershipDashboard/Silver.png";
import Gold from "@assets/membershipDashboard/Gold.png";
import Platinium from "@assets/membershipDashboard/Platinum.png";
import Diamond from "@assets/membershipDashboard/Diamond.png";
import { ReactComponent as Close } from "@assets/close.svg";
import 'react-circular-progressbar/dist/styles.css';
import MemberShipWarningModal from "./memberShipWarningModal";
import MemberShipFirstTimeView from "./membershipDashboardFirstTime"
import {
  useGetUserProfileQuery,
  useLinkToSpeedSocietyMutation,
  useUnlinkToSpeedSocietyMutation,
} from "@redux/services/userProfile/UserProfileService";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import ButtonLoader from "@components/Loaders/ButtonLoader";
import SpeedSocietyTab from "./MembershipDashboardSpeedSociety";
import CarvonixStatisticsTab from "./CarvonixPointsStats";
import StatisticsData from "./StaticCard";
import IntractionMemberDashboard from "./IntractionMemberDashboard";

const MembershipDashboardModal = ({ open, closeModal }) => {
  const [Tab, setTabs] = useState(1);
  const [openWarning, setOpenWarning] = useState(false);
  const [linkToSpeedSociety, { isLoading: isLinking }] = useLinkToSpeedSocietyMutation();
  const [unlinkToSpeedSociety, { isLoading: isUnlinking }] = useUnlinkToSpeedSocietyMutation();
  const { data: { data: { user: userInfo } = {} } = [], isFetching, refetch } = useGetUserProfileQuery();
  const currentRank = userInfo?.interactionPoint?.ranks ? userInfo.interactionPoint.ranks : 1;
  const [showFirstTime, setShowFirstTime] = useState(userInfo.speedSociety.firstTimeSeen ? userInfo.speedSociety.firstTimeSeen : false);
  const [speedSoietyConnected, setSpeedSoietyConnected] = useState(userInfo.speedSociety.status ? userInfo.speedSociety.status : 0);
  // const interactionRanks = {
  //   NEWBIE: 1,
  //   BRONZE: 2,
  //   SILVER: 3,
  //   GOLD: 4,
  //   PLATINUM: 5,
  //   DIAMOND: 6,
  // };
  const interactionRankName = [
    'Newbie',
    'Bronze Member',
    'Silver Member',
    'Gold Member',
    'Platinum Member',
    'Diamond Member',
  ];
  const interactionRankColors = [
    "rgba(142, 142, 147, 1)'",
    'rgba(216, 172, 100, 1)',
    '#C5C5C5',
    '#FFAC0F',
    '#A7DAF8',
    '#8076FF'
  ];

  const medalList = [
    { imageName: '', point: 0, totalTiers: 0 },
    { imageName: 'Bronze', totalTiers: 5, src: Bronze },
    { imageName: 'Silver', totalTiers: 4, src: Silver },
    { imageName: 'Gold', totalTiers: 3, src: Gold },
    { imageName: 'Platinum', totalTiers: 2, src: Platinium },
    { imageName: 'Diamond', totalTiers: 1, src: Diamond },
  ];

  const getRankName = rank => {
    return {
      name: interactionRankName[rank - 1],
      color: interactionRankColors[rank - 1],
    };
  };

  const handleChange = (key) => {
    setTabs(key);
  };

  const handleCloseFirstTime = () => {
    setShowFirstTime(true);
    setTabs(1);
  };
  const handleParent = () => {
    if (openWarning) {
      setOpenWarning(false)
    }
  }
  const handleConnection = (e) => {

    if (speedSoietyConnected === 1) {
      setOpenWarning(true);
    }

  }
  const handleCloseModal = async (str) => {
    if (str === "sure") {
      setOpenWarning(false);
      try {
        await unlinkToSpeedSociety();

        await refetch();

      } catch (error) {
        console.log(error);
      }

      setSpeedSoietyConnected(0);
    } else {
      setOpenWarning(false);
    }
  };


  const handleLinkToSociety = async () => {
    try {
      await linkToSpeedSociety();
      setShowFirstTime(true);
      setTabs(2);
      await refetch();

    } catch (error) {
      console.log(error);
    }

    setSpeedSoietyConnected(1);
  };
  return (
    <MembershipDashboardWrapper
      open={open}
      closeModal={closeModal}
      className="membershipDashboardModal"
      style={{
        top: "55%",
        width: "100%",
        maxWidth: "740px",
        minHeight: "630px",
        transform: "translateY(-50%)",
        borderRadius: "10px",
        left: 0,
        right: 0,
        margin: "0 auto",
        backgroundColor: "#18191A",
        boxShadow: "2px 8px 20px -6px #0A0A0A",
      }}
    >


      <div className="membershipDashboardHeader">
        <img src={infoSvg} alt="info-icon" />
        <p>Dashboard</p>
        <button className="close-modal-button" onClick={closeModal}>
          <Close />
        </button>
      </div>
      <hr style={{ margin: '0' }} />
      <div className="container" onClick={() => { handleParent() }}>
        <div className="row" >
          <div className="col-lg-5">
            {/* ///////////////////////////////////////interaction Points////////////////////////////////////////////   */}
            <div className="memberLevel">
              <IntractionMemberDashboard
                currentRank={currentRank}
                medalList={medalList}
                userInfo={userInfo}
              />

            </div>


          </div>
          <div className="statistics col-lg-7">
            <p>Statistics</p>
            <p
              style={{
                color: "#8E8E93",
                fontWeight: "400",
                fontSize: "13px",
                lineHeight: "16.9px",
              }}
            >
              Tap card to see more details
            </p>
            <StatisticsData
              Tab={Tab}
              handleChange={handleChange}
              userInfo={userInfo}
              speedSoietyConnected={speedSoietyConnected}
            />
            <div className="linkToSpeedSociety">
              {/*---------------------------------------- User Cx Points  Tab-----------------------------------------*/}
              {Tab === 1 && showFirstTime ? (
                <CarvonixStatisticsTab userInfo={userInfo} />
              ) : Tab === 2 && showFirstTime ? (
                //----------------------------------------- User Speed Society Tab---------------------------------------
                <SpeedSocietyTab
                  isFetching={isFetching}
                  isUnlinking={isUnlinking}
                  userInfo={userInfo}
                  speedSoietyConnected={speedSoietyConnected}
                  handleConnection={handleConnection}
                  handleLinkToSociety={handleLinkToSociety}
                  isLinking={isLinking}
                />

              ) :

                speedSoietyConnected === 0 && (<>
                  <MemberShipFirstTimeView onClose={handleCloseFirstTime} />



                  <div className="linkToSocietyBtn">
                    <button style={{ width: Tab === 0 ? "90%" : "100%" }} onClick={handleLinkToSociety}>{isLinking ? <ButtonLoader /> : "Link To Speed Society"}</button>
                  </div></>)


              }



            </div>
          </div>
        </div>
      </div>




      {openWarning && (
        <MemberShipWarningModal
          open={openWarning}
          closeModal={(key) => handleCloseModal(key)}
        />
      )}


    </MembershipDashboardWrapper>
  );
};

export default MembershipDashboardModal;




