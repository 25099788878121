import React, { useEffect, useRef } from "react";
import LandingForm from "@components/LandingForm";
import { CloseStories, HeroLogo, PlayIcon } from "@utils/defaultImages";
import useMediaQuery from "@utils/hooks/useMediaQueryHook";

const HeroSection = () => {
  const isMobile = useMediaQuery({ minWidth: 425, maxWidth: 599 });
  const isMobileSmall = useMediaQuery({ minWidth: 320, maxWidth: 424 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  const isTabScr = useMediaQuery({ minWidth: 1024, maxWidth: 1320 });
  const isMobTab = useMediaQuery({ minWidth: 600, maxWidth: 767 });

  const videoRef = useRef(null); // Reference to the iframe

  // Stop video when modal is closed
  useEffect(() => {
    const modal = document.getElementById("videoModal");

    const handleModalClose = () => {
      const iframe = videoRef.current;
      if (iframe) {
        const src = iframe.src;
        iframe.src = ""; // Clear the iframe src to stop the video
        iframe.src = src; // Re-assign the src to restore the video for next play
      }
    };

    // Add event listener for Bootstrap's hidden.bs.modal event
    modal.addEventListener("hidden.bs.modal", handleModalClose);

    // Cleanup event listener on component unmount
    return () => {
      modal.removeEventListener("hidden.bs.modal", handleModalClose);
    };
  }, []);

  return (
    <>
      <div className="hero-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 px-4 py-5 d-flex flex-column justify-content-center every-car">
              <HeroLogo />
              <div className="title">
                <h1 className="py-4">Every Car Enthusiast's Dream App</h1>
                <p>
                  Seamlessly exchange ideas, spotlight your builds, and
                  collaborate with like-minded enthusiasts in your local area.
                </p>
              </div>
              <div
                className="py-4 d-flex gap-3 watch-ved"
                style={{ cursor: "pointer" }}
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#videoModal"
                  style={{ backgroundColor: "#387AFF" }}
                >
                  Watch video
                  <img
                    src={PlayIcon}
                    alt="PlayIcon"
                    style={{ marginLeft: "6px" }}
                  />
                </button>
              </div>
            </div>

            <div
              className={`col-lg-6 col-md-12 col-sm-12 col-12 py-5 launches ${
                isTablet || isMobTab
                  ? "d-flex justify-content-center"
                  : "d-flex justify-content-end"
              }`}
            >
              <LandingForm />
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade"
        id="videoModal"
        tabIndex="-1"
        aria-labelledby="videoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-80 modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="closeModal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="white"
                width="16"
                height="16"
              >
                <path d="M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z" />
              </svg>
            </button>
            <div className="modal-body">
              <div className="video-responsive">
                <iframe
                  ref={videoRef} // Attach reference to iframe
                  src="https://player.vimeo.com/video/1014971040?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
