import { useState } from "react";

export default function ReadMore({ sliceText,isShowMarginTop=false,isBreakWord=false, children }) {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className={`text ${isShowMarginTop ? "" : "mt-3"}`} style={{wordBreak:isBreakWord?"break-word":""}}>
      {isReadMore ? text?.slice(0, sliceText) : text}
      {text?.length > sliceText && (
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "...read more" : " show less"}
        </span>
      )}
    </p>
  );
}
