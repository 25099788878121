import React, { useState } from "react";
import "@containers/searchcomponent/SearchPage.scss";
import UserImage from "@assets/carDetailsImgs/user.png";
import { Link } from "react-router-dom";

const ExploreAccounts = () => {
  return (
    <div>
      <div className="view-all-heading">
        <h2>Accounts</h2>
      </div>

      <div
        className="crew-list-content-search"
        style={{ gridTemplateColumns: "1fr 1fr" }}
      >
        {/* {loading ? (
          <>
            <FadingLoader />
          </>
        ) : (
          <>
            {filteredAccount?.map((item, index) => {
              // console.log('item11', item)
              return ( */}
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
          <div className="list-block user-list-block">
            <Link
              // to={`/profile/${item?._id}`}
              className="list-block"
              style={{
                borderBottom: "none",
                color: "#fff",
                padding: "unset",
                marginBottom: "unset",
              }}
            >
              <img src={UserImage} alt="" className="main-img" />
              <div className="list-info">
                <h4>Thomas</h4>
                <p>Shelby</p>
              </div>
            </Link>
            <button className="default-btn">Follow</button>
          </div>
        ))}

        {/* );
            })}
          </>
        )} */}
      </div>
    </div>
  );
};

export default ExploreAccounts;
