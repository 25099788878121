import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./landingForm.scss";
import { useRegisterWaitingUserMutation } from "@redux/services/public/publicService";
import { toast } from "react-toastify";
import { Turnstile } from "@marsidev/react-turnstile";

const LandingForm = () => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .matches(
        /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/,
        "Invalid Input"
      )
      .min(10, "Phone number length is too short. It should be at least 10")
      .required("Please enter your phone number"),
  });

  /**
   * API CALL
   */
  const [waitingUser, { isLoading }] = useRegisterWaitingUserMutation();

  return (
    <>
      <div className="landing-form">
        <div className="line-pass pt-5 pb-2">
          <h4>Starting Line Pass</h4>
          <p>
            Sign up to be first in line when the app launches later this year.
          </p>
        </div>
        <div className="pass-input px-2">
          <Formik
            initialValues={{ firstName: "", email: "", phoneNumber: "" }}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              try {
                const response = await waitingUser(values).unwrap();
                if (response?.code === 200) {
                  toast.success(
                    "Thank you! Your record has been save Successfully. Our team will get back to you."
                  );
                  resetForm();
                }
              } catch (err) {
                toast.error(err?.data?.message);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="form-data my-3">
                  <Field
                    type="text"
                    name="firstName"
                    className="form-control-lg bg-transparent custom-input"
                    placeholder="Name"
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-data">
                  <Field
                    type="email"
                    name="email"
                    className="form-control-lg bg-transparent custom-input"
                    placeholder="Email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-data mt-3">
                  <Field
                    type="text"
                    name="phoneNumber"
                    className="form-control-lg bg-transparent custom-input"
                    placeholder="Number"
                  />
                  <ErrorMessage
                    name="phoneNumber"
                    component="div"
                    className="error"
                  />
                </div>
                {/* <div className="submit px-3 pt-3">
                  <p>
                    By submitting this form and signing up for texts, you
                    consent to receive marketing text messages (e.g. promos,
                    cart reminders) from Carvonix at the number provided,
                    including messages sent by autodialer. Consent is not a
                    condition of purchase. Msg & data rates may apply. Msg
                    frequency varies. Unsubscribe at any time by replying STOP
                    or clicking the unsubscribe link (where available).{" "}
                    <span>Privacy Policy</span> & <span>Terms</span>.
                  </p>
                </div> */}
                <Turnstile
                  siteKey={process.env.REACT_APP_RECAPTCHA_KEY_SECRET}
                  className="d-flex justify-content-center mt-3"
                  onError={() => console.log("CAPTCHA verification failed")}
                  onSuccess={() => console.log("CAPTCHA verification Success")}
                />
                <div className="submit-btn py-4">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={isSubmitting || isLoading}
                  >
                    {isLoading ? "Loading..." : "Submit"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default LandingForm;
