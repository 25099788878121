import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Bolt from "@assets/membershipDashboard/bolt.svg";
import Avatar from "@assets/membershipDashboard/Avatar.png";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const IntractionMemberDashboard = ({ currentRank, medalList, userInfo }) => {
  const getRankName = (rank) => {
    const interactionRankName = [
      "Newbie",
      "Bronze Member",
      "Silver Member",
      "Gold Member",
      "Platinum Member",
      "Diamond Member",
    ];
    const interactionRankColors = [
      "rgba(142, 142, 147, 1)",
      "rgba(216, 172, 100, 1)",
      "#C5C5C5",
      "#FFAC0F",
      "#A7DAF8",
      "#8076FF",
    ];
    return {
      name: interactionRankName[rank - 1],
      color: interactionRankColors[rank - 1],
    };
  };

  return (
    <>
      <div className="memberLevelHeader">
        <div className="image-username">
          <img
            src={userInfo.profileImage ? userInfo.profileImage : Avatar}
            alt="user-image"
          />
          <div className="username">
            <p>{userInfo.username ? userInfo.username : "Frank Glinski"}</p>
            <p
              style={{
                color:
                  getRankName(userInfo?.interactionPoint?.ranks)?.color ||
                  "#D8AC64",
              }}
            >
              {getRankName(userInfo?.interactionPoint?.ranks)?.name ||
                "Bronze Member"}
            </p>
          </div>
        </div>
        <div className="circularProgress">
          <CircularProgressbar
            value={userInfo.carvonixPointStats.percentage || 50}
            text={`${
              userInfo.carvonixPointStats.percentage
                ? Math.ceil(userInfo.carvonixPointStats.percentage)
                : 50
            }%`}
            styles={{
              path: {
                stroke:
                  getRankName(userInfo?.interactionPoint?.ranks)?.color ||
                  "#D8AC0F",
              },
              text: {
                fill: "#FFFFFF",
                fontSize: "14px",
              },
              trail: {
                stroke: "#323334",
              },
            }}
          />
        </div>
      </div>
      <div className="memberLevelBody">
        {currentRank === 1 ? (
          <div className="newbie">
            <div className="newbie-paragraph">
              <p>Get Started Earning Carvonix Points</p>
            </div>
            <div className="newbie-button">
              <button>Watch Tutorial</button>
            </div>
            <div className="learn-more">
              <p>Learn more</p>
            </div>
          </div>
        ) : (
          <Swiper
            slidesPerView={3}
            centeredSlides={true}
            initialSlide={currentRank - 1}
            pagination={{ clickable: false }}
            modules={[Pagination]}
            className="mySwiper"
            autoplay={false}
            allowTouchMove={false}
            onSwiper={(swiper) => {
              const handleSlideChange = () => {
                const bullets = document.querySelectorAll(
                  ".swiper-pagination-bullet"
                );
                const activeIndex = swiper.activeIndex;

                bullets.forEach((bullet, index) => {
                  if (index <= activeIndex) {
                    bullet.classList.add("swiper-pagination-bullet-inactive");
                  } else {
                    bullet.classList.remove(
                      "swiper-pagination-bullet-inactive"
                    );
                  }
                });
              };

              swiper.on("slideChange", handleSlideChange);
              handleSlideChange();
            }}
          >
            {medalList.map((medal, index) => (
              <SwiperSlide key={index}>
                <img src={medal.src} alt={medal.name} />
                {index === currentRank - 1 && (
                  <div className="tier-information">
                    <p>Tier {userInfo?.interactionPoint?.ranks + 1}</p>
                  </div>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
      <div className="memberFooter">
        <button>
          <img src={Bolt} alt="" />
          <p>
            {userInfo.carvonixPointStats.pointsToNextTier
              ? userInfo.carvonixPointStats.pointsToNextTier
              : 50}{" "}
            more points needed to advance to tier
          </p>
        </button>
      </div>
    </>
  );
};

export default IntractionMemberDashboard;
