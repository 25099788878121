import React from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import routes from "@routes";
import { Suspense } from "react";

function Router() {
  const user = useSelector((state) => state?.auth?.userAuthInfo);
  const routeConfig = routes(user);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {routeConfig?.map((route, index) => {
          const { path, element, children } = route;

          return (
            <Route key={index} path={path} element={element}>
              {children &&
                children?.map((child, childIndex) => (
                  <Route
                    key={childIndex}
                    path={child?.path}
                    element={child?.element}
                  />
                ))}
            </Route>
          );
        })}
      </Routes>
    </Suspense>
  );
}

export default Router;
